const dk = {
  language: 'dk',
  userRoles: {
    admin: 'Admin',
    planner: 'Normal',
    read_only_planner: 'Læs Adgang',
    plus_planner: 'Plus',
    partner: 'Partner',
    customer: 'Kunde',
    customs_agent: 'Toldagent',
    handler: 'Behandler'
  },

  application: {
    requiredLocation: '*Lokation er påkrævet',
    recipient: 'Modtager',
    save: 'Gem',
    update: 'Opdater',
    required: '*Obligatorisk felt',
    requiredShort: '*Obligatorisk',
    logOut: 'Log ud',
    proceed: 'Fortsæt',
    search: 'Søg...',
    courier: 'Bud',
    couriers: 'Bude',
    consignments: 'Forsendelser',
    address: 'Adresse',
    estimated: 'Estimeret',
    adjusted: 'Justeret',
    actual: 'Faktisk',
    funeral: 'Begravelse',
    tracking: 'Sporing',
    trackingPage: 'Modtagers sporingsside',
    delivery: 'Levering',
    phoneNumberShort: 'Tlf. nr.',
    yes: 'Ja',
    no: 'Nej',
    httpErrorToString: 'HTTP fejl: kode {{status}} {{statusText}}',
    anErrorOccurred: 'En fejl opstod!',
    noAccessTitle: 'Ingen adgang',
    noAccessMessage: 'Du har ikke adgang til at bruge denne funktionalitet',
    autoComplete: 'Søg efter adresse...',
    autoCompleteWithoutDots: 'Søg efter adresse',
    totalWeight: 'Total vægt',
    totalVolume: 'Total volumen',
    validDeviationCode: 'Indtast venligst en gyldig værdi',
    title: {
      root: 'Velkommen',
      planner: 'Planlægning',
      admin: 'Administrator',
      plannerLive: ' Planlægning - Live',
      plannerLiveSlot: ' Planlægning - Live - Rute',
      plannerCouriers: ' Planlægning - Bud',
      plannerImport: 'Planlægning - Importere',
      plannerUnresolved: 'Planlægning - Uløste',
      plannerConsignments: 'Planlægning - Forsendelser',
      plannerSearch: 'Planlægning - Søg',
      plannerPlanning: 'Planlægning - Planlægning ',
      plannerInvoicing: 'Planlægning - Fakturering',
      plannerVehicle: 'Planlægning - Vehicles',
      report: 'Rapporter',
      customerDepartmentLive: 'Live oversigt',
      recipient: 'Pakkesporing - Sporing - Bring',
      instant: 'Ad Hoc',
      orderConfirmation: 'Ordrebekræftelse',
      customerPortal: 'Kundeportal',
      instantMap: 'Realtids kort',
      recurringOrder: 'Gentagne ordrer',
      corrections: 'Ordre korrektioner',
      jobs: 'Job overvågning',
      holidays: 'Helligdage',
      shipmentLabels: 'Sendingsetiketter',
      addresses: 'Adresser',
      airexpressRecurringOrder: 'Air Express Gentagne ordrer',
      airexpressInvoicing: 'Air Express Fakturering',
      routeReceiptList: 'Kvitteringsliste',
      loadList: 'Læsseliste',
      airexpressAdminTerminals: 'Terminaler'
    },
    language: { en: 'Engelsk', nb: 'Norsk', sv: 'Svensk', dk: 'Dansk', fi: 'Finsk' },
    country: {
      AllCountry: 'Alle lande',
      Country: 'Land',
      AT: 'Østrig',
      BE: 'Belgien',
      BA: 'Bosnien-Hercegovina',
      BG: 'Bulgarien',
      HR: 'Kroatien',
      CZ: 'Tjekkiet',
      DK: 'Danmark',
      GB: 'Det Forenede Kongerige',
      EE: 'Estland',
      FO: 'Færøerne',
      FR: 'Frankrig',
      DE: 'Tyskland',
      GR: 'Grækenland',
      HU: 'Ungarn',
      IS: 'Island',
      IE: 'Irland',
      IT: 'Italien',
      LV: 'Letland',
      LT: 'Litauen',
      MK: 'Nordmakedonien',
      NL: 'Holland',
      NO: 'Norge',
      PL: 'Polen',
      RO: 'Rumænien',
      RU: 'Rusland',
      RS: 'Serbien',
      SK: 'Slovakiet',
      SI: 'Slovenien',
      ES: 'Spanien',
      SE: 'Sverige',
      CH: 'Schweiz'
    },
    timeDistance: 'Planlagt tid og afstand',
    driverReturnToAddress: 'Angiv ikke en returadresse',
    plannedTime: 'Planlagt tid',
    plannedDistance: 'Planlagt afstand',
    plannedWeight: 'Planlagt vægt',
    distance: 'Afstand'
  },
  serviceLevel: { notSet: 'Ikke valgt', none: 'Af', label: 'Nedgradere serviceniveau' },
  button: {
    edit: 'Rediger',
    back: 'Tilbage',
    cancel: 'Annuller',
    done: 'Færdig',
    clear: 'Ryd',
    close: 'Luk',
    save: 'Gem',
    delete: 'Slet',
    export: 'Eksport',
    new: 'Lav ny',
    search: 'Søg',
    add: 'Tilføj',
    remove: 'Fjern',
    assign: 'Tildel',
    fullEdit: 'Fuld redigering',
    continue: 'Fortsæt'
  },
  modals: {
    preplan: {
      create: 'Forudplanlæg forsendelse',
      change: 'Ændre forudplanlagt chauffør',
      shipmentInfo: 'Forudplanlæg forsendelse {{shipmentId}} ({{pickupName}} til {{deliveryName}})',
      pickDriver: 'Vælg et bud..',
      unpreplan: 'Ophæv forudplanlægning',
      preplan: 'Forudplanlæg',
      error: 'Kunne ikke forudplanlægge forsendelsen',
      notification: {
        preplanned: 'Forudplanlagt',
        unpreplanned: 'Forudplanlæggelse ophævet',
        preplannedMessage: 'Forsendelse forudplanlagt til {{courierId}}',
        unpreplannedMessage: 'Forudplanlægningen på forsendelsen er ophævet'
      }
    },
    maps: {
      save: 'Gem',
      cancel: 'Annuller',
      delete: 'Slet',
      editMode: 'Rediger',
      mapArea: '{{routeName}} kortområde',
      saved: 'Gemt',
      deleted: 'Slettet'
    }
  },
  consignment: {
    includePreAdvice: 'Preavisering',
    onlyFailedPreAdvice: 'Preavisering fejlet',
    onlyReturnPreAdvice: 'Retur til afsender',
    consignmentDetails: 'Forsendelsesdetaljer',
    search: 'Forsendelsessøgning',
    noShipmentsFound: 'Ingen forsendelser fundet',
    clickButtonToSearch: 'Klik på søg knappen for at søge',
    consignmentsForDate: 'Forsendelser fra {{dato}}',
    showOnlyEditable: 'Kun redigerbare',
    showOnlyNotAssigned: 'Ikke behandlet',
    withoutDate: 'Uden dato',
    extendedSearch: 'Udvidet søgning',
    showDeleted: 'Vis slettede',
    showDeviated: 'Vis afvegne',
    deletedBy: 'Slette af',
    deletionReason: 'Årsag',
    numberOfConsignments: 'Fundet {{nummer}} forsendelser',
    showAll: 'Alle',
    delivery: 'Leveres',
    pickup: 'Opsamling',
    pickups: '{{antal}} opsamling',
    pickups_plural: '{{antal}} opsamlinger',
    edit: 'Rediger',
    deleteOrders: 'Slet',
    duplicateOrder: 'Kopiere',
    copyOrder: 'Kopier',
    editOrder: 'Rediger',
    deletedOrderNotCopyable: 'Slettet ordre kan ikke kopieres da der mangler ordredetaljer i Glow',
    restoreOrder: 'Gendan',
    deletedOrderNotRestorable: 'Slettet ordre kan ikke gendannes da der mangler ordredetaljer i Glow',
    deleteConfirmation: 'Er du sikker på, at du vil slette denne forsendelse?',
    deleteMultipleConfirmation: 'Er du sikker på at du vil slette valgte forsendelser?',
    copyConfirmation: 'Er du sikker på, at du vil kopiere denne forsendelse?',
    editConsignment: 'Rediger forsendelsesoplysninger',
    searchShipments: 'Søg i forsendelser:',
    filterBy: 'Sorter efter:',
    searchCustomers: 'Søg i kunder:',
    limitedShipmentsShownMessage: 'Viser maksimalt {{count}} forsendelser, overvej at indsnævre din søgning',
    limitedUnresolvedAddressesShownMessage: 'Der kan højst vises {{count}} uløste adresser',
    bulkActions: 'Ændre forsendelser',
    bulkActionsMultiple: 'Ændre forsendelser ({{count}})',
    addToRecurringGroup: 'Føj til den tilbagevendende opgave',
    moveShipmentsToDifferentDepartment: 'Flyt til en anden afdeling',
    addEvents: 'Tilføj handling',
    disabledEditDeleteHelperText:
      'Denne ordre kan ikke redigeres eller slettes, kontakt venligst dit lokale Bring kontor for at ændre i ordren.',
    editPickupConsignment: 'Opdater opsamlingsoplysninger',
    editDeliveryConsignment: 'Opdater leveringsoplysninger',
    editPickupAndDeliveryTime: 'Opdater afhentnings- og leveringstider',
    editConsignmentLocation: 'Lokation',
    editConsignmentTime: ' Tid',
    editConsignmentName: ' Navn',
    editConsignmentPhone: ' Telefonnummer',
    editConsignmentInstructions: ' Instruktioner',
    earliestPickupTime: 'Tidligste opsamlingstidspunk*',
    latestPickupTime: 'Seneste opsamlingsstidspunkt *',
    earliestDeliveryTime: 'Tidligste leveringstid',
    latestDeliveryTime: 'Seneste leveringstid',
    enterPickupInstructions: 'Angiv opsamlingsinstruktioner',
    enterDeliveryInstructions: 'Angiv leveringsinstruktioner',
    enterDeliveryName: 'Angiv Leveringsnavn',
    enterPickupName: 'Angiv opsamlingsnavn',
    enterPickupPhone: 'Angiv opsamlingstelefonnummer',
    enterDeliveryPhone: 'Angiv leveringstelefonnummer',
    specificationSize: 'Specifikationsstørrelse',
    enterSpecificationSize: 'Indtast specifikationsstørrelse',
    editSpecificationSize: 'Rediger specifikationsstørrelse',
    enterSpecificationDescription: 'Indtast beskrivelse',
    editSpecificationDescription: 'Rediger beskrivelse',
    editPickupInfoDescription: 'For at kunne redigere info skal du vælge en af ovenstående muligheder',
    updateLocationFor: 'Opdater information for {{antal}} forsendelser',
    updateLocationFor_plural: 'Opdater opsamlingsoplysninger for {{antal}} forsendelser',
    comment: 'Kommentar',
    show: 'Vis',
    filter: 'Filtrer forsendelser',
    state: 'Status',
    estimatedDelivery: 'Forventet levering',
    requestedTimePickup: 'Anmodet opsamlingstidspunkt',
    requestedTimeDelivery: 'Anmodet leveringstidspunkt',
    timeNotSet: 'Ikke indstillet',
    customerReference: 'Kundereference',
    shipmentId: 'Forsendelsesnummer',
    packageId: 'Pakkenummer',
    noCourier: 'Intet bud',
    noSlot: 'Ingen rute',
    showEvents: 'Vis hændelser →',
    hideEvents: 'Skjul hændelser ←',
    events: 'Hændelser',
    sendMessage: 'Send besked',
    sendCommentFailed: 'Kunne ikke gemme kommentar',
    recipient: 'Modtager',
    unresolved: 'Ufærdiggjort forsendelse',
    description: 'Beskrivelse',
    pickupAddress: 'Opsamlingsadresse',
    numberOfPackages: 'Antal pakker',
    registeredPickupAddress: 'Registreret opsamlingsadresse',
    pickupZipCode: 'Postnummer',
    pickupZipArea: 'Område',
    pickupLocation: 'Lokation',
    deliveryAddress: 'Leveringsadresse',
    deliveryZipCode: 'Postnummer',
    deliveryZipArea: 'By',
    deliveryLocation: 'Lokation',
    registeredDeliveryAddress: 'Registreret leveringsadresse',
    updated: 'Forsendelsen er opdateret',
    moved: 'Forsendelsen er blevet flyttet',
    unresolvedMessage: 'Der er {{count}} forsendelse, hvor vi ikke kan finde adressen, venligst tilføj den.',
    unresolvedMessage_plural: 'Der er {{count}} forsendelser, hvor vi ikke kan finde adressen, venligst tilføj dem.',
    foundUnresolvedMessage:
      'Vi har fundet følgende gyldige addresser til denne forsendelse. Hvis de er korrekte, skal du trykke på opdater. Ellers finder du den rigtige adresse på nedenstående kort.',
    remove: 'Fjern',
    alterSlot: 'Ændre rute (uden optimering)',
    alterSlotAndReoptimize: 'Ændre rute (optimer ny tid med samme rækkefølge)',
    alterSlotNotAvailable: 'Ikke tilgængelig under optimering',
    optimizingNewSlot: 'Optimering af forsendelse på ny rute',
    selectSlot: 'Vælg ny rute',
    slotSelect: 'Vælg rute',
    confirmSlotChange: 'Bekræft ruteændring',
    pickupInstructions: 'Opsamlingsinstruktioner',
    deliveryInstructions: 'Leveringsinstruktioner',
    specificationDescription: 'Beskrivelse',
    tracking: 'Sporing',
    recipientPhone: 'Modtagerens telefonnummer',
    customerRef: 'Kundereference',
    message: {
      CREATED: 'Oprettet',
      ASSIGNED: 'Tildelt til rute',
      UNASSIGNED: 'Ikke tildelt til rute',
      COLLECTED: 'Opsamlet',
      DELIVERED: 'Leveret',
      DEVIATED: 'Afveget',
      COMMENT: 'Kommentar',
      RESOLVED: 'Løst',
      UNRESOLVED: 'Uløst',
      OFFERED: 'Tilbudt',
      REJECTED: 'Afvist',
      ACCEPTED: 'Accepteret',
      RETURNED: 'Returneret',
      DELETED: 'Slettet'
    },
    manualOverrideTitle: 'Manuel tilretning',
    adjustTabTitle: 'Redigere',
    mapPanelTitle: 'Kort',
    recipientName: 'Modtagers navn',
    receivedBy: 'Modtaget af:',
    manuallyChangeLocations: 'Skift placering manuelt',
    manualOverrideDeviationTimeConfirm: 'Bekræft manuel afvigelse for valgte forsendelse',
    manualOverrideDeliveryTimeConfirm: 'Bekræft manuel levering for valgte forsendelse',
    onManuallyChangeLocationsConfirm: 'Er du sikker på at du vil gøre forsendelsen uløst?',
    unresolveDeliveryAddress: 'Gør leveringsadressen uløst',
    unresolvePickupAddress: 'Gør opsamlingsadressen uløst',
    shipmentIdNumber: 'Nr',
    shipmentDetails: 'Forsendelsesoplysninger',
    customer: 'Kunder',
    subcustomer: 'Kunde nr.',
    dateAndTime: 'Dato og tid',
    filterCustomer: 'Filtrer kunde',
    searchShipmentDateError: 'Indtast datointerval under 120 dage.',
    searchPhraseLength: 'Søgefraser bør være mindst {{minLength}} tegn for en udvidet søgning.',
    moveOrder: 'Flyt forsendelse',
    moveOrderTitle: 'Flyt forsendelse til en anden afdeling',
    moveOrderDefault: 'Vælg afdeling',
    createLabel: 'Opret label',
    createLabelShipment: 'Opret label (Forsendelse)',
    createMultipleLabel: 'Opret label ({{count}})',
    viewLabel: 'Se label',
    viewLabels: 'Se labels',
    generatingLabels: 'PDF label links vil være tilgængelige nedenfor om et øjeblik...',
    labelsGeneratedSuccessfully: 'Alle PDF labels er genereret succesfuldt',
    generateLabelErrorDescription: 'Der kan desværre ikke genereres labels på nuværende tidspunkt',
    vas: 'Tillægstjenester (VAS)',
    price: 'Pris',
    orderSources: { glow_customerBooking: 'GLOW Kunde booking', glow_recurring: 'Gentagne' },
    shipmentSyncStatus: { yes: 'Ja', no: 'Nej' },
    unresolvedSuccessfully: 'Forsendelsen er nu uløst.',
    wrongCoordinates: 'Forkerte koordinater?',
    moveShipmentToUnresolved: 'Flyt forsendelsen til "Uløste" for at rette koordinaterne.',
    mapUnavailable: 'Kortet vises når forsendelsen er løst.',
    selectOtherActions: 'Vælg andre handlinger'
  },
  eventsList: { timeWindow: 'Tidsvindue', from: 'Fra', to: 'Til' },
  consignmentEvent: {
    message: {
      comment: 'Kommentar',
      created: 'Oprettet',
      completed: 'Afsluttet',
      assigned: 'Tildelt bud',
      plannedOnRoute: 'Planlagt til rute',
      plannedOnRouteMoreInfo: 'Planlagt til rute {{routeName}} ({{unitName}})',
      delivered: 'Leveret',
      collected: 'Opsamlet',
      returned: 'Returneret',
      deviated: 'Afviget',
      damaged: 'Skadet',
      notification: 'Notifikation',
      slotChanged: 'Rute ændret manuelt',
      unassigned: 'Fjernet fra bud',
      rejected: 'Afvist',
      image: 'Billede',
      error: 'Fejl',
      started: 'Startet',
      stopped: 'Afsluttet',
      accepted: 'Accepteret',
      offered: 'Tilbudt',
      unitName: 'Enhed',
      driverName: 'Bud',
      'delivery-id-check': 'ID tjek',
      'delivered-manually-overridden': 'Levering manuelt tilpasset',
      'deviated-manually-overridden': 'Afvigelse manuelt tilpasset',
      'collected-manually-overridden': 'Afhentning ændret manuelt',
      'pickup-location-changed': 'Opsamlingsadresse ændret',
      'delivery-time-estimated': 'Opdateret leveringstid',
      'slot-manually-changed': 'Rute manuelt tilpasset',
      'pickup-time-changed': 'Opsamlingstidspunkt ændret',
      'manually-change-location': 'Manuel tilpasning af placering',
      'pickup-consignment-data-changed': 'Opsamlingsoplysninger ændret',
      'delivery-consignment-data-changed': 'Leveringsoplysninger ændret',
      'specification-size-data-changed': 'Størrelsesspecifikationer ændret',
      'specification-description-data-changed': 'Oplysninger ændret',
      'delivery-time-purchased': 'Specifikt tidsrum bestilt af modtager',
      'digital-identity-verified': 'Modtagers identitet bekræftet digitalt',
      'digital-identity-verification-failed': 'Kunne ikke verificere modtagerens identitet digitalt',
      'flex-delivery-ordered': 'Forenklet levering bestilt af modtagert',
      'flex-delivery-ordered-by-sender': 'Forenklet levering bestilt af afsender',
      'flex-delivery-confirmation-sms-sent': 'Flex levering bekræftelses SMS afsendt',
      'return-to-pickup-point-delivery-deviated': 'Vil blive afleveret til udleveringsstedet',
      recipientName: 'Modtagers navn',
      receivedBy: 'Modtaget af',
      'reject-order-correction': 'Afvist ordrekorrektion',
      'accept-order-correction': 'Accepteret ordrekorrektion',
      'handed-over-to-other-unit': 'Overgivet til anden unit',
      deliveredWithoutSignature: 'Leveret uden signatur',
      deliveredWithoutSignatureReason: {
        left_package_outside: 'Placeret som instrueret',
        did_not_get_signature: 'Personligt'
      },
      'returned-manually-overridden': 'Manuel ændring af retur',
      scanned: 'Ankommet på terminal',
      'arrived-at-terminal': 'Ankommet på distribuerende terminal',
      'order-sorted': 'Sorteret',
      'not-arrived-at-distributing-terminal': 'Ikke ankommet distribusjonsterminal',
      'pickup-eta-sms-sent': 'Opsamlings ETA SMS sendt',
      'handed-in-at-posten-terminal': 'Ankommet til terminal',
      'pickup-sms-sent': 'Sporings SMS sendt',
      'pre-pickup-sms-sent': 'Sporings SMS forhåndssendt',
      'pickup-address-unresolved': 'Afhentningsadresse er blevet gjort uløst',
      'delivery-address-unresolved': 'Leveringsadressen er blevet gjort uløst',
      'upcoming-pickup-sms-sent': 'SMS for kommende levering sendt',
      'order-sorted-same-day': 'Dato er sat til i dag af terminal app',
      'signature-sms-sent': 'Underskrift SMS afsendt',
      'flex-delivery-cancelled': 'Simplified delivery afbestilt',
      'delivery-time-ordered': 'Leveringsdato ændret af modtager',
      'delivery-time-ordered-internal': 'Leveringsdato ændret',
      'delivery-time-updated-from-hf-sync': 'Leveringstid synkroniseret fra HF',
      'planned-delivery-date-ordered': 'Planlagt leveringsdato bestilt',
      'delay-sms-sent': 'SMS om forsinkelse sendt',
      'manual-delay-sms-sent': 'Manuel SMS for forsinkelse sendt',
      'route-planning-complete': 'Rute færdig',
      'signature-delivered': 'Underskrift fra modtager',
      showPicture: 'Vis billede',
      'external-address-wash': 'Leveringsoplysninger ændret',
      'order-note-changed': 'Notat ændret',
      'order-data-changed': 'Ordre ændret',
      'order-moved-to-department': 'Flyttet mellem department',
      'order-moved-to-department-with-data': 'Forsendelsen er flyttet fra',
      'package-measurements-changed': 'Pakkestørrelse ændret',
      'driver-deviated-from-route': 'Chaufføren afveg fra ruten',
      'delivered-outside-service-time-window': 'Levering eller afvigelse udenfor tjenestens tidsvindue',
      'delivered-outside-estimated-time-window': 'Levering eller afvigelse udenfor estimeret tidsvindue',
      'seen-by-recipient': 'Sporingsside set af modtager',
      'first-estimated-times-seen': 'Første estimerede tid set af modtager',
      'pre-advice-push-sms-sent': 'Preadvice PUSH-SMS sendt',
      'pre-advice-push-return-sms-sent': 'Preadvice PUSH-RETURN-SMS sendt',
      'pre-advice-pull-sms-sent': 'Preadvice PULL-SMS sendt',
      'pre-advice-pull-reminder-sms-sent': 'Preadvice PULL påmindelse til modtager sendt',
      'pre-advice-pup-sms-sent': 'Preadvice PUP-SMS sendt',
      'pre-advice-return-sms-sent': 'Preadvice Return-SMS sendt',
      'pre-advice-return-email-sent': 'Preadvice Return-email sendt',
      'delivery-location-deviation': 'Levering eller afvigelse ikke på forventet sted',
      'pre-advice-pull-reminder-to-sender-email-sent': 'Preadvice PULL påmindelse til afsender epost sendt',
      'pre-advice-pup-reminder-to-sender-email-sent': 'Preadvice PUP påmindelse til afsender epost sendt',
      'pre-advice-return-return-to-sender-email-sent': 'Preadvice RETURN retur til afsender epost sendt',
      'pre-advice-pull-return-to-sender-email-sent': 'Preadvice PULL retur til afsender epost sendt',
      'pre-advice-push-return-email-sent': 'Preadvice PUSH retur til afsender epost sendt',
      'pre-advice-manual-attempt': 'Manuel preadvice-forsøg',
      'pre-advice-manual-request': 'Manual preadvice startet',
      'pre-advice-active-return-to-customer-email-sent': 'Preadvice aktiv retur til kunde, epost sendt',
      'pre-advice-active-return-not-picked-up-email-sent': 'Preadvice aktiv retur ble ikke hentet, epost sendt',
      'pre-advice-passive-return-to-customer-email-sent': 'Preadvice passiv retur til kunde, epost sendt',
      'delivery-sms-sent': 'Levering SMS sendt',
      'parcelbox-allocated': 'Der er reserveret plads i pakkeboksen',
      'apartment-information-updated': 'Lejlighedsoplysninger opdateret',
      'invoicing-failed': 'Fakturering mislykkedes',
      'invoiced-ok': 'Fakturering OK',
      serviceUpgradePurchasedDescription: 'Indbæring bestilt af modtageren',
      'preliminary-loaded': 'Foreløbigt lastet',
      'manual-price-override': 'Pris overskrevet',
      'incomplete-price-approved': 'Ufuldstændig pris godkendt',
      'incomplete-price-unapproved': 'Godkendelse af ufuldstændig pris tilbagekaldt'
    },
    rejectedReason: {
      reason: 'Årsag',
      private: 'Private årsager',
      notEnoughTime: 'Kan ikke nå stoppet inden for tiden',
      notEnoughSpaceOnVehicle: 'Bilen har ikke kapacitet til godset',
      other: 'Anden'
    },
    idCheck: {
      idCheckFailed: 'ID control mislykkedes, men blev leveret med {{idCheckFailedResult}}',
      idCheckSummaryOther: 'Anden ({{otherType}}), fødselsdato {{birthDate}}',
      idCheckSummary: '{{idCheckType}}, fødselsdato {{birthDate}}'
    },
    damaged: {
      damageType: 'Type skade',
      causeCode: 'Årsagskode',
      courierInput: 'Besked fra budet',
      showPictureLink: 'Vis billede {{idx}}',
      type: { A: 'Skade på æske/emballage', D: 'Tryk/klemskade', E: 'Totalskadet' }
    },
    invoiceInitiationType: {
      automatic: 'Planlagt fakturering',
      'manual-department-level': 'Manuel afdelingsfakturering',
      'manual-order-level': 'Manuel ordrefakturering'
    },
    changedFrom: 'Ændret fra -'
  },
  numberSeries: { numberSeries: 'Nummerserie' },
  preadvice: {
    hdDepartments: 'HD departments',
    noPoliciesFound: 'No Preadvice policies',
    policies: 'Preadvice policies',
    policy: {
      name: 'Name',
      new: 'New preadvice policy',
      edit: 'Edit preadvice policy',
      targets: 'Customer-/department connections',
      servicePolicy: {
        edit: 'Edit rule',
        new: 'Add service preadvice rule',
        serviceCodeLegend: 'Add service code e.g. 3123',
        triggerEventLegend:
          'What should trigger the preadvice send out. When we get the EDI to GLOW, When the packages are received at HUB or received at DIP.',
        returnToPup: 'Retur til pickup point'
      }
    }
  },
  holidays: { holidays: 'Helligdage', addHoliday: 'Tilføy en ny helligdag', country: 'Land', date: 'Dato' },
  department: {
    departmentTitle: 'Afdeling',
    department: 'afdeling',
    department_plural: 'afdelinger',
    departmentWithCount: '{{count}} afdeling',
    departmentWithCount_plural: '{{count}} afdelinger',
    departmentGroups: 'Afdelingsgrupper',
    numberOfDepartmentGroups: 'Antal afdelingsgrupper',
    numberOfDepartments: 'Antal afdelinger',
    search: 'Søg efter afdelinger',
    noDepartmentName: 'Navn mangler',
    noDepartmentId: 'Id mangler',
    noCouriers: 'Ingen bude',
    courierCount_plural: '{{count}} bude',
    courierCount: '{{count}} bud',
    noCourier: 'Intet bud',
    couriers: 'Antal bude',
    plannerCount_plural: '{{count}} planlæggere',
    plannerCount: '{{count}} planlægger',
    noPlanner: 'Ingen planlægger',
    planners: 'Antal planlæggere',
    choose: 'Vælg afdeling',
    new: 'Ny afdeling',
    description: 'Beskrivelse',
    name: 'Navn',
    created: 'Afdeling oprettet',
    updated: 'Afdeling opdateret',
    departmentType: 'Afdelingstype',
    notSet: 'Ikke valgt',
    courier: 'Bud',
    hd: 'Hjemmelevering',
    other: 'Andre',
    all: 'Alle',
    deliveryDisplayType: 'Visning af leveringstid',
    terminalName: 'Terminalnavn',
    terminalNameTooltip: 'Hvis afhetnings- og afdelingsnavn ikke er det samme, så udfyld følgende',
    selectArea: 'Vælg område...',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'Område',
    orderBillingType: {
      title: 'Ordrefaktureringstype',
      selectBillingType: 'Vælg faktureringstype',
      customerBillingNumber: 'Kundens faktureringsnummer',
      addMoreOrderBillingInfo: 'Tilføj ny ordrefaktureringstype'
    },
    departmentId: 'Afdelings Id',
    autoInvoiceTitle: 'Automatisk fakturering',
    autoInvoiceEnable: 'Sett automatisk fakturering',
    autoInvoiceTime: 'Tid',
    autoInvoiceOffset: {
      legend: 'Hvilke ordrer der skal faktureres',
      oneDayEarlier: 'Leveret 1 dag siden',
      twoDaysEarlier: 'Leveret 2 dage siden',
      threeDaysEarlier: 'Leveret 3 dage siden',
      fourDaysEarlier: 'Leveret 4 dage siden',
      fiveDaysEarlier: 'Leveret 5 dage siden'
    }
  },
  departmentGroup: {
    departmentGroup: 'Afdelingsgruppe',
    name: 'Navn',
    deleteConfirm: 'Er du sikker på at du vil slette denne afdelingsgruppe?',
    edit: 'Rediger afdelingsgruppe'
  },
  deliveryDisplayType: { instant: 'Ad Hoc', customized: 'Tilpasset' },
  menu: {
    defaultDisabled: 'Vælg en kunde for at aktivere dette menupunkt',
    home: 'Hjem',
    planner: 'Planlægning',
    report: 'Rapporter',
    customer: 'Kunder',
    apiOrder: 'Find API ordre',
    priceMotor: 'Prismotor'
  },
  planner: {
    chooseYourDepartment: 'Vælg din afdeling',
    unassignedShipments: 'Uanviste forsendelser',
    filterCustomers: 'Filtrer kunder',
    filterServices: 'Filtrer tjenester',
    autoRoute: 'Automatisk rute',
    saved: 'Gemt',
    allUserRoles: 'Alle brugerroller',
    userRolesCount_plural: '{{count}} brugerroller',
    createNewUser: 'Opret ny bruger',
    userRolesCount: '{{count}} brugerrolle',
    role: 'Rolle',
    searchForNameOrNumber: 'Søg efter navn eller telefonnummer',
    noPlannersFound: 'Ingen planlæggere fundet',
    typeOfRole: 'Type af brugerrolle',
    selectUserRole: 'Vælg brugerrolle',
    users: 'Brugere',
    connectDepartment: 'Forbind afdeling til bruger',
    banner: {
      tryNewRoutePlanner:
        'Fra den 1. maj vil <0>ruteplanlægger 2.0</0> være den eneste tilgængelige ruteplanlægger. Hvis du ikke har brugt ruteplanlæggeren før, opfordrer vi dig til at begynde at bruge den nu. <1>Læs mere på Zendesk</1> og andre relaterede artikler for at få et overblik over, hvordan den fungerer.'
    },
    totalTitle: 'Pakker',
    pickList: 'Plukliste',
    export: 'Eksport til fakturering',
    deleteSlot: 'Slet',
    editSlot: 'Rediger',
    deleteSlotConfirmDialogText: 'Er du sikker på at du vil slette denne rute?',
    deleteFilteredSlots: 'Slet alle ruter',
    deleteAllSlotsConfirmDialogText: 'Er du sikker på, at du vil slette alle ruter?',
    created: 'Planlægger oprettet',
    removeDepartment: 'Fjern afdeling {{name}}',
    connectAirlineTerminal: 'Tilslut flyterminal(er) til bruger',
    connectedAirlineTerminals: 'Tilknyttede flyterminaler ({{count}})',
    noConnectedTerminals: 'Ingen tilknyttede terminaler endnu',
    removeTerminal: 'Fjern terminal {{name}}',
    searchTerminals: 'Søg efter flyterminaler',
    youMustSelectTerminal: 'Du skal vælge flyterminal',
    youMustProvideName: 'Du skal angive brugerens navn',
    youMustProvidePhonenumber: 'Du skal angive telefonnummer',
    updated: 'Planlægger opdateret',
    routePlanning: 'Rute planlægning',
    routePlanningConsignmentsAssigned: 'tildelt',
    routePlanningRangeDescription: 'Filtrer forsendelser efter leveringstid',
    unresolvedConsignments: 'Uløst',
    consignments: 'Forsendelser',
    couriers: 'Bud',
    units: 'Enheder',
    invoicing: 'Fakturering',
    planner: 'Planlægger',
    planners: 'Planlæggere',
    noPlanners: 'Ingen planlæggere',
    missingId: 'Id mangler',
    missingName: 'Navn mangler',
    search: 'Søg',
    searchForPlanner: 'Søg efter planlægger',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    selectDepartments: 'Vælg afdeling',
    createSlot: 'Opret rute',
    editSlotForm: 'Rediger rute',
    routeUpdated: 'Rute oplysninger opdateret',
    createSlotFormName: 'Rute navn',
    createSlotFormUnit: 'Enhed',
    createSlotFormCourier: 'Bud',
    createSlotFormSlotMode: 'Rute mode',
    createSlotFormSlotModePickup: 'Tegn afhentningsområde på kort',
    createSlotFormSlotModeDelivery: 'Tegn leveringsområde på kort',
    createSlotFormSlotModeManual: 'Vælg forsendelse manuelt',
    createSlotFormSlotModeSelected: 'Vælg forsendelser',
    createSlotFormTimeRange: 'Tidsinterval',
    createSlotFormStartLocation: 'Start lokation',
    createSlotFormEndTime: 'Sluttid',
    createSlotFormEndLocation: 'Stop lokation',
    createSlotFormSlotColor: 'Rutefarve',
    createSlotFormTimeRangeTooBig: 'Tidsintervallet er for stort. Maks er {{hours}} timer.',
    createSlotFormFilterCustomers: 'Filtrer kunder (ingen markeret betyder alle)',
    createSlotFormFilterServices: 'Filtrer service (ingen markeret betyder alle)',
    slotFilterInfo:
      'Ruten er filtreret, hvilket betyder at kun forsendelser, der tilhører den/de valgte kunde(r) og den/de valgte service(s), kan tilknyttes ruten.',
    slotFilteredCustomer: 'Ruten indeholder kun forsendelser fra følgende kunder',
    slotFilteredService: 'Ruten indeholder kun forsendelser med følgende services',
    onlyShowApplicable: 'Vis kun ordrer der er anvendelige for denne rute, baseret på kunde/service filtreringen',
    optimizeButtonLabel: 'Optimer og tildel ruter',
    optimizeSlotButtonLabel: 'Genoptimer rute',
    reversedOptimizeSlotButtonLabel: 'omvendt leveringsrækkefølge',
    optimizingSince: 'Optimering i gang, startede kl. {{startTime}}',
    alterSlotIdOnConsignment: 'Er du sikker på, at du vil ændre ruten for denne forsendelse?',
    addToRoute: 'Føj til rute...',
    pickup: 'Opsamling',
    delivery: 'Levering',
    slot: 'Rute',
    notAssigned: 'Ikke behandlet',
    readOnly: 'Kun læsetilladelse',
    readOnlyAccessPlannersWithCount: '{{count}} planlæggere med læsetilladelse',
    plusPlannersWithCount: '{{count}} planlæggere med ekstra rettigheder',
    normalPlannersWithCount: '{{count}} planlæggere med standard rettigheder',
    saveTemplate: 'Gem skabelon',
    loadTemplate: 'Hent fra skabelon',
    routeListForFilteredSlots: 'Kvitteringsliste (Print alt)',
    loadListForFilteredSlots: 'Læsseliste (Print alt)',
    sendPrePickupSmsesToAll: 'Forhåndssend sporings SMS',
    alertBeforeSendingPrePickupSms: 'Er du sikker på at du vil forhåndssende sporings SMS for alle slots for i dag?',
    createTemplateFormName: 'Skabelonnavn',
    createOrUpdateTemplateDescription:
      'For at gemme, skal du enden skrive et unikt skabelonnavn, eller opdatere en eksisterende skabelon',
    newTemplate: 'Opret en ny skabelon',
    overwriteTemplate: 'Overskriv skabelon',
    templateSaved: 'Skabelonen er gemt',
    updateTemplateDescription: 'Vælg eksisterende skabelon for at opdatere',
    updateTemplateConfirm: 'Er du sikker på du vil opdatere denne skabelon?',
    selectOrDeleteTemplateDescription: 'Vælg en skabelon, der skal bruges eller slettes',
    selectTemplate: 'Vælg skabelon',
    deleteTemplate: 'Slet',
    selectTemplateDescription: 'Vælg skabelon:',
    templateSelected: 'Skabelonen er anvendt',
    templateDeleted: 'Skabelonen er slettet',
    templateDeleteConfirm: 'Er du sikker på, du vil slette denne skabelon?',
    missingDeliveryMarker: 'Ikke en gyldig leveringsadresse eller lokation',
    missingPickupMarker: 'Ikke en gyldig afhentningsadresse eller lokation',
    unassignConsignmentFromSlot: 'Fjern forsendelse fra rute',
    unassignBulkConsignmentsFromSlotsAlert: 'Fjern forsendelser fra deres respektive ruter?',
    unassignedFromSlot: 'Forsendelsen er nu blevet fjernet fra ruten',
    unassignBulkConsignmentsFromSlots: 'Forsendelserne er nu blevet fjernet fra deres respektive ruter',
    unassignTitle: 'Fjern forsendelsen fra ruten',
    unassignButton: 'Fjern fra rute',
    forceUnassignButton: 'Tving fjern fra rute',
    moveOrder: 'Er du sikker på, du vil flytte denne forsendelse?',
    driverName: 'Chauffør',
    filters: { assigned: 'Behandlet', unassigned: 'Ikke behandlet', pickups: 'Opsamlinger', deliveries: 'Leveringer' },
    saveSelected: 'Gem valgte ({{count}})',
    showSaved: 'Vis gemte ({{count}})',
    showAll: 'Vis alt',
    clearSaved: 'Slet gemte',
    sendPrePickupSms: 'Forhåndssend sporings SMS til modtager',
    sendingSmsText: 'Sender {{count}} sporings SMS',
    prePickupSmsSent: '{{sentCount}} af {{totalCount}} sporings SMS afsendt',
    manualDelaySms: {
      smsSent: '{{count}} manuel SMS for forsinkelse sendt',
      heading: 'Manuel SMS for forsinkelse',
      sendingSmsText: 'Sender {{count}} manuel SMS for forsinkelse',
      selectSmsDescription:
        'SMS vil starte med "Hej! Din levering fra Sender er desværre forsinket.". Vælg SMS afslutning nedenfor.',
      selectSmsEnding: 'Vælg SMS afslutning',
      endingType: {
        none: 'Ingen',
        soon: '"Vi vil forsøge at levere så hurtigt som muligt."',
        tomorrow: '"Vi vil forsøge at levere næste hverdag."'
      },
      disabledTooltip: 'Ordrer kan ikke være i tilstand Leveret'
    },
    h2: { vasHelperText: 'Brug VAS 1158 til MBH' },
    loadList: {
      header: 'Ruteoversigt',
      listName: 'Læsseliste',
      unknownRouteName: 'Ukendt rutenavn',
      unknownUnitName: 'Ukendt enhedsnavn',
      noDataText: 'Ikke noget at se her'
    },
    locationList: { listName: 'Lokationsliste' },
    assignedUnits: 'Tildelte enheder',
    unassignedUnits: 'Ikke-tildelte enheder',
    noUnitSelected: 'Ingen enhed valgt',
    unitAssignmentCount: 'Enhed tildelt til {{count}} ruter'
  },
  plannerDashboard: {
    vehicleTypes: {
      car: 'Bil',
      bicycle: 'Cykel',
      truck: 'Lastbil',
      van: 'Varebil',
      motorcycle: 'Motorcykel',
      cargobike: 'Lastcykel'
    }
  },
  updateServiceLevel: {
    menuItem: 'Ret serviceniveau',
    selectOption: 'Vælg serviceniveau',
    heading: 'Ret servicenivea',
    confirmation: 'Service niveau opdateret',
    subtext: 'Ret serviceniveau for {{count}} forsendelse',
    subtext_plural: 'Ret serviceniveau for {{count}} forsendelser',
    failed: {
      title: 'Serviceniveau kunne ikke ændres',
      message: 'Serviceniveau for {{count}} forsendelse kunne ikke ændres',
      message_plural: 'Serviceniveau for {{count}} forsendelser kunne ikke ændres'
    }
  },
  manualPreAdvice: {
    menuItem: 'Manuel preavisering',
    selectOption: 'Vælg type preavisering',
    heading: 'Manuel preavisering',
    confirmation: 'Preavisering(er) igangsat',
    subtext: 'Igangsæt preavisering av {{count}} forsendelse',
    subtext_plural: 'Igangsæt preavisering av {{count}} forsendelse',
    failed: {
      title: 'Preavisering kunne ikke igansættes',
      message: 'Kunne ikke igangsætte preavisering av {{count}} shipment',
      message_plural: 'Kunne ikke igangsætte preavisering av {{count}} shipments'
    }
  },
  setPickupDeliveryDuration: {
    menuItem: 'Indstil afhentnings- / leveringsvarighed',
    heading: 'Indstil afhentnings- og leveringsvarighed',
    subtext: 'Indstil afhentnings- og leveringsvarighed for {{count}} forsendelse',
    subtext_plural: 'Indstil afhentnings- og leveringsvarighed for {{count}} forsendelser',
    pickupDuration: 'Afhentningsvarighed',
    deliveryDuration: 'Leveringsvarighed',
    confirmation: 'Opdateret varighed',
    tooltipTitle: 'Når du indtaster varighederne, er det maksimale antal minutter og sekunder 59'
  },
  importExportOrders: {
    importSuccess: 'Alle ordrer i regnearket tilføjes eller opdateres i systemet',
    importSuccessTitle: 'Succes',
    importOrdersHeading: 'Importordre',
    importStatus: 'Der er i øjeblikket {{imports}} importer i kø med et samlet antal på {{orders}} ordrer',
    exportForInvoice: 'Eksport til fakturering',
    fileTemplateLink: 'Download Excel-skabelon',
    uploadFile: 'Importordre',
    uploadingMessage: 'Uploader fil og importerer ordrer ...',
    needToSelectFile: 'Du skal vælge en fil',
    exportSuccess: 'Alle ordrer i det valgte datointerval downloades til regnearket',
    exportSuccessTitle: 'Succes',
    dateFormat: 'DD.MM.ÅÅÅÅ',
    testDataWarning: 'Brug ikke personlige oplysninger i testmiljø',
    testDataInfo:
      'Dette er et qa/testmiljø, og du må ikke bruge produktionsdata i test uden udtrykkeligt samtykke fra berørte brugere.',
    fromDate: 'Fra dato',
    toDate: 'Til dato',
    fromDateNotAfterToDate: "Kan ikke være senere end 'To date'",
    toDateNotBeforeFromDate: "Kan ikke være tidligere end 'From date'",
    removeControlColumns: 'Fjern kontrollkolonner',
    importResults: 'Importer resultater',
    fileName: 'Importeret fil',
    processedAt: 'Behandlet',
    createdShipmentId: 'Created Shipping ID',
    date: 'Dato',
    exportShipments: 'Eksportskibe',
    selectSlot: 'Vælg rute',
    selectExportLabel: 'Vælg hvad du vil eksportere',
    allOrders: 'Alle Ordrer',
    deviatedOrders: 'Ordre med afvigelse',
    all: 'Alle',
    noSelection: 'Intet valg',
    selectFile: 'Vælg fil',
    noFileSelected: 'Ingen fil',
    updateSelectedFile: 'Opdater fil',
    jobList: {
      title: 'Tidligere eksporter',
      content: 'Indhold',
      created: 'Oprettet',
      user: 'Bruger',
      dates: 'Dato',
      download: 'Downloadet',
      exporting: 'Eksporterer...',
      inQueue: 'I eksportkø',
      exportFailed: 'Eksporten mislykkedes',
      updated: 'Opdateret',
      previousImports: 'Tidligere import',
      fileName: 'Filnavn',
      numberOfOrders: 'Antal ordrer',
      imported: 'Importeret',
      viewOrders: 'Vis ordrer',
      lastImports: 'Viser de sidste {{count}} importer'
    },
    exportStartedNotification: 'Eksporten startede',
    exportStartedNotificationMessage: 'Din rapport vil være tilgængelig, så snart den er behandlet.',
    exportFailedNotification: 'Eksporten mislykkedes. Prøv igen senere',
    refreshData: 'Opdater data i eksisterende rapport',
    refreshDataDescription:
      'Vælg denne mulighed, hvis du allerede har oprettet en eksisterende rapport for de specifikke datoer',
    types: {
      SHIPMENT_EXPORT: 'Forsendelser',
      AIREXPRESS_SHIPMENTS: 'Forsendelser (med AirExpress-data)',
      COURIER_WORK_REPORT: 'Chaufførarbejdsrapport',
      SOCIAL_CONTROL: 'Social kontrol',
      LOCATION_LIST: 'Placeringsliste',
      ORDER_EXPORT: 'Eksport af ordre',
      ORDER_DEVIATION_EXPORT: 'Ordre (Afvigelse)',
      ORDER_EXPORT_ANONYMOUS: 'Ordre (Anonyme Data)',
      ORDER_PARTNER_EXPORT: 'Ordre (For Partnere)',
      DEVIATION_EVENTS: 'Afvigelseshændelser'
    },
    exportTitles: {
      SHIPMENT_EXPORT: 'Eksporter forsendelser',
      AIREXPRESS_SHIPMENTS: 'Eksporter forsendelser (med AirExpress-data)',
      COURIER_WORK_REPORT: 'Chaufførarbejdsrapport',
      SOCIAL_CONTROL: 'Eksporter Social kontrol',
      LOCATION_LIST: 'Eksporter placeringsliste',
      ORDER_EXPORT: 'Eksportordre',
      ORDER_DEVIATION_EXPORT: 'Eksportordre med afvigelse',
      ORDER_EXPORT_ANONYMOUS: 'Eksportordre Anonymiseret',
      ORDER_PARTNER_EXPORT: 'Eksportpartnerordre',
      DEVIATION_EVENTS: 'Eksport afvigelseshændelser'
    },
    exportDescriptions: {
      AIREXPRESS_SHIPMENTS: 'Dette inkluderer AirExpress data, ud over normale sendingsdata.',
      ORDER_EXPORT:
        'Hvis du ønsker at bruge data i testmiljøer, anbefaler vi at bruge "Bestil (anonymiserede data)" mulighed',
      ORDER_DEVIATION_EXPORT:
        'Hvis du ønsker at bruge data i testmiljøer, anbefaler vi at bruge "Bestil (anonymiserede data)" mulighed',
      ORDER_EXPORT_ANONYMOUS: 'Kan bruges, hvis data skal importeres til et testmiljø',
      ORDER_PARTNER_EXPORT: 'Tilpasset Excel-fil, der kan bruges til at importere i Opter'
    }
  },
  unit: {
    unitWithCount: '{{count}} enhed',
    unitWithCount_plural: '{{count}} enheder',
    new: 'Ny enhed',
    edit: 'Rediger enhed',
    addCourier: 'Tilføj bud',
    hideAddCourier: 'Skjul tilføj bud',
    requiresCourierUsers: 'Skal have mindst et bud',
    created: 'Enheden er blevet oprettet',
    updated: 'Enheden er blevet opdateret',
    search: 'Søg efter enheder fra andre afdelinger...',
    unitsInCurrentDepartment: 'Enheder i nuværende afdeling',
    unitsInOtherDepartments: 'Enheder i andre afdelinger',
    saveUnit: 'Gem enhed',
    addCourierToUnit: 'Tilføj bud til enhed'
  },
  courier: {
    isActive: 'Aktiv',
    canLogIn: 'Kan logge ind',
    recipientName: 'Modtagers navn',
    created: 'Buddet er blevet oprettet',
    updated: 'Buddet er blevet opdateret',
    courier: 'Bud',
    couriers: 'Bude',
    noCouriers: 'Ingen bude',
    courierWithCount: '{{count}} bud',
    courierWithCount_plural: '{{count}} bude',
    numberOfCouriers: 'Fundet {{number}} bude',
    multipleDrivers: 'Flere bude',
    missingId: 'Id mangler',
    missingName: 'Navn mangler',
    search: 'Søg efter bud',
    new: 'Nyt bud',
    edit: 'Rediger bud',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    driverId: 'Bud-ID',
    capacity: 'Kapacitet',
    weightCapacity: 'Vægtkapacitet (kg)',
    volumeCapacity: 'Volumenkapacitet (m³)',
    maxRangeKm: 'Rækkevidde (km)',
    color: 'Farve',
    resetColor: 'nulstil farve',
    speedFactor: 'Hastighedsfaktor',
    speedFactorExplanation: 'lavere = langsommere',
    serviceTimeFactor: 'Service tidsfaktor',
    serviceTimeFactorExplanation: 'lavere = hurtigere',
    defaultStopLocation: 'Standard stop lokation',
    defaultStartLocation: 'Standard start lokation',
    slotNumDeliveries: '{{count}} levering',
    slotNumDeliveries_plural: '{{count}} leveringer',
    slotNumPickups: '{{count}} opsamling',
    slotNumPickups_plural: '{{count}} opsamlinger',
    deliverPerformButtonLabel: 'aflever',
    handoverPerformButtonLabel: 'Overlevere',
    deviationPerformButtonLabel: 'Afvigelse',
    deliverWithoutSignatureButtonLabel: 'Uden underskrift',
    deliverWithSignatureButtonLabel: 'Underskrift',
    deviateNotAtHomeButtonLabel: 'Ingen hjemme',
    deviateLocNotFoundButtonLabel: 'Kunne ikke finde lokation',
    deviateRecipientDeclinedButtonLabel: 'Modtageren ønsker ikke varerne',
    deviateTimeWindow: 'Kan ikke levere indenfor tidsvinduet',
    addressNotFound: 'Adresse ikke fundet',
    deliverySpotUnavailable: 'Aftalt leveringssted utilgængeligt',
    deviation: {
      'H-12': 'Der blev gjort et forsøg på at levere forsendelsen, men modtageren var ikke til stede',
      'H-11': 'Der er gjort forsøg på at levere pakken, men modtageren blev ikke fundet på adressen',
      'H-10': 'Der er forsøgt at levere pakken, men adressen blev ikke fundet',
      'H-13': 'Levering af sendingen er forsøgt, men forsendelsen blev afvist',
      '7-10': 'Varer kunne ikke afhentes på grund af ufuldstændig adresse',
      '7-12': 'Afsenderen var ikke tilgængelig. Varerne er ikke afhentet',
      '7-82': 'Varerne var ikke klar til afhentning',
      'V-41-K': 'Forsendelsen er forsinket, videresendes så hurtigt som muligt',
      'H-07-R': 'Mislykket social kontrol',
      'H-07-Z': 'Mislykket social kontrol',
      'H-08-Z': 'Mislykket alderskontrol',
      'H-09-Z': 'Mislykket ID kontrol',
      'VI-16-X': 'Kan ikke levere indenfor tidsvinduet',
      'H-11-Z': 'Adresse ikke fundet',
      'H-BL-A': 'Aftalt leveringssted utilgængeligt'
    },
    completionProgressCounter: '{{numCompleted}}/{{numTotal}} gennemført',
    lockCode: 'Dørkode',
    flexDelivery: 'Simpel levering',
    fuelType: 'Brændstoftype',
    fuelTypes: { gasoline: 'Benzin', natural_gas: 'Naturgas', electric: 'Elektricitet', bicycle: 'Cykel' },
    fuelCategory: 'Brændstofkategori',
    fuelCategories: { fossil: 'Fossilt brændstof ', fossil_free: 'Fossilfri', zero_emissions: 'Nul-udledning' },
    latestLogin: 'Seneste login',
    latestVersion: 'Seneste version',
    failedSocialControl: 'Mislykket social kontrol',
    failedIdControl: 'Mislykket ID kontrol',
    failedAgeControl: 'Mislykket alderskontrol',
    incompletePickupAddress: 'Ufuldstændig opsamlingsadresse',
    custNotAvailableAtPickupAddress: 'Afsenderen var ikke tilgængelig',
    goodsNotReadyAtPickupForCust: 'Varer er ikke klar til afhentning hos kunden',
    shipmentNotAvailableAtTerminal: 'Forsendelsen er ikke klar på terminalen',
    registrationNumber: 'Registreringsnummer',
    commercialTrafficEligibility: 'Kommerciel trafikberettigelse',
    vehicleBrand: 'Køretøjsmærke',
    vehicleModel: 'Køretøjsmodel',
    vehicleControl: 'Køretøjskontrol',
    vehicleControlComment: 'Køretøjskontrol kommentar',
    alcoholDeliveryEducation: 'Uddannelse for levering af alkohol',
    approved: 'Godkendt',
    notApproved: 'Ikke godkendt',
    couriersInCurrentDepartment: 'Bude i nuværende afdeling',
    editCourier: 'Ret bud:',
    courierUserUpdated: 'Bud er blevet opdateret.'
  },
  slot: {
    minutesDelay: 'minutter forsinket',
    minutesAhead: 'minutter foran',
    minutes: 'minutter',
    time: 'Tid',
    created: 'Oprettet',
    assigned: 'Behandlet',
    lock: 'Lås',
    lockDescription:
      'Når en rute er låst, vil den ikke være en del af fremtidige ruteoptimeringer og kan ikke redigeres.',
    toggleVisibleSlotDescription: 'Skjul rute.',
    inProgress: 'I gang',
    onHold: 'Afventer',
    complete: 'Gennemført',
    unknownState: 'Ukendt tilstand',
    courierSelect: 'Vælg bud',
    unitSelect: 'Vælg enhed',
    printableSlotList: 'Printbar ruteliste',
    followsRoute: 'Følger',
    doesNotFollowRoute: 'Afviger',
    hasLocation: 'Synlig',
    hasNoLocation: 'Ikke synlig',
    showActiveSlots: 'Vis igangværende',
    showSlotNotStarted: 'Vis startede',
    showCompletedSlot: 'Vis gennemførte',
    showPerfectSlot: 'Vis perfekte',
    filterSlots: 'Filtrer ruter',
    planningCompleted: 'Færdig',
    addMapArea: 'Vælg område på kort',
    editMapArea: 'Rediger område på kort'
  },
  login: {
    phone: 'Indtast dit Glow-registrerede telefonnummer med landekode (f.eks. +45 …), så sender vi en SMS-kode.',
    code: 'Koden er sendt, hvis du har registreret en bruger med dette nummer. Den er gyldig i 5 minutter. Indtast koden.',
    requestNewCode: 'Anmod om en ny kode',
    verifyFailed:
      'Kode eller telefonnummer er forkert, eller for mange forsøg på login er brukt, prøv igen eller anmod om en ny kode',
    requestFailed: 'Kunne ikke sende kode, prøv igen.',
    verifyTokenButton: 'Bekræft kode',
    requestTokenButton: 'Anmod om kode',
    privacyLink: 'Fortrolighedspolitik',
    passwordWrong: 'Forkert password',
    customer: 'Jeg er en kunde',
    forgotPassword: 'Glemt password?',
    tryApp: 'Prøv den nye App!',
    appDownload: 'Download chauffør-appen her og få adgang til endnu flere funktioner!',
    appInfo:
      'Er du en chauffør med en Android- telefon? Download chauffør-appen <1>her</1> og få adgang til endnu flere funktioner!',
    note: 'Bemærk! Brug kun webversionen af Bud-Appen, hvis du af en eller anden grund ikke kan bruge apps til iOS og Android',
    resendToken: 'Gensend kode',
    heading: 'Log ind',
    submitPassword: 'Indsend adgangskode',
    forgotPasswordDescription:
      'For at fortsætte skal du angive en adgangskode. Vi sender dig en e-mail med et link til at angive denne. Hvis din e-mail er ændret, må du kontakte en administrator for at ændre den.',
    sendEmailDescription:
      'For at fortsætte har vi brug for din e-mail. Vi sender dig et bekræftelseslink til den angivne adresse. Med dette link kan du sætte din totrins adgangskode.',
    loginAsCustomer: 'Log ind som kunde',
    loginAsPlanner: 'Log ind som planlægger',
    token: 'Kode',
    password: 'Adgangskode',
    tokenRequired: 'Kode er påkrævet',
    emailFormatValidation: 'E-mail skal være i gyldigt format',
    sendEmailSuccess: 'E-mailen burde komme snart',
    setYourPassword: 'Angiv din adgangskode',
    sendEmail: 'Send e-mail',
    passwordsDontMatch: 'Adgangskoderne stemmer ikke overens',
    passwordNotMeetRequirements: 'Adgangskoden opfylder ikke kravene',
    passwordRequired: 'Adgangskode er påkrævet',
    newPassword: 'Ny adgangskode',
    repeatPassword: 'Gentag din adgangskode',
    lowercaseLetter: 'Et lille bogstav',
    uppercaseLetter: 'Et stort bogstav',
    numberCharacter: 'Et tal',
    minimumCharacters: 'Minimum 15 tegn',
    passwordDescription: 'Du kan nu angive din adgangskode. Den skal indeholde følgende'
  },
  error: {
    '404Title': 'Side ikke fundet',
    '404Message': 'Vi kan ikke finde siden du leder efter',
    '500Message': '{{error}} – Beklager, vi kan ikke finde dine data… Vi forsøger igen om et minut…',
    title: 'Noget gik galt!',
    dateRequiredWhenSecureLogin: '*Obligatorisk felt for BankID er aktiveret',
    dateNotValid: 'Ugyldig dato',
    errorMessage: 'Vi beklager! Det virkede ikke som planlagt, men udviklerne er blevet underrettet om fejlen.',
    errorClickToReload: 'Klik her for at genindlæse siden',
    phoneNumber: 'Du skal angive en gyldigt telefonnummer, f.eks. +45 12345678',
    phoneNumberPrefix: 'Du skal angive en landekode, f.eks. +45 ...',
    somethingWentWrong: 'Noget gik galt da vi forsøgte at gemme…',
    notAValidNumber: 'Ikke et gyldigt nummer',
    notAValidAlphaNumeric: 'Skal være tall og/eller bogstaver',
    notAValidDate: 'Skal være en gyldig dato (åååå-mm-dd)',
    notAValidTime: 'Skal være et gyldigt tidspunk (tt:mm)',
    notAValidDuration: 'Skal være en gyldig varighed (mm:ss)',
    notAValidZipCode: 'Skal være et gyldig postnummer',
    notAValidTimezone: 'Skal være en gyldig tidssone. Prøv "Europe/Copenhagen" eller noget som ligner',
    somethingWentWrongOnTheServer: 'Noget gik galt på serveren...',
    somethingWentWrongWithTheRequest: 'Der var noget galt med anmodningen...',
    somethingWentWrongWithTheResponse: 'Der var noget galt med svaret...',
    selectAtLeastOne: 'Du må vælge mindst et af valgene',
    timeInWrongFormat: 'Tiden er ikke gyldig'
  },
  loading: 'Vi henter dine data...',
  newAppVersion: 'Glow blev lige en smule bedre, vi henter ny version nu.',
  loadingReceiptList: 'Kvitteringslister genereres...',
  loadingLabelUrl: 'PDF label links er ved at blive genereret…',
  invoicing: { departmentInvoicingInProgress: 'Fakturering i gang...' },
  months: {
    january: 'Januar',
    february: 'Februar',
    march: 'Marts',
    may: 'Maj',
    june: 'Juni',
    july: 'Juli',
    october: 'Oktober'
  },
  weekdays: {
    monday: 'Mandag',
    tuesday: 'Tirsdag',
    wednesday: 'Onsdag',
    thursday: 'Torsdag',
    friday: 'Fredag',
    saturday: 'Lørdag',
    sunday: 'Søndag'
  },
  weekdaysShort: {
    sunday: 'Søn',
    monday: 'Man',
    tuesday: 'Tir',
    wednesday: 'Ons',
    thursday: 'Tor',
    friday: 'Fre',
    saturday: 'Lør'
  },
  maps: { addressLookup: 'Søg efter adresse' },
  notifications: {
    dismiss: 'Afvis',
    dismissAll: 'Afvis alle',
    error: 'Fejl',
    reload: 'Opdater',
    reloadCourier: 'Tryk her for at genindlæse',
    navigate: 'Naviger til side',
    networkError: 'Det ser ud til, at der er netværksproblemer, det kan være midlertidigt. Men siden skal genindlæses.',
    networkErrorCourier: 'Du har været offline, vær venlig at genindlæse.'
  },
  grid: {
    columns: {
      department: 'Afdeling',
      failedReason: 'Fejlmeddelelse',
      name: 'Navn',
      senderName: 'Afsendernavn',
      zip: 'Postnr.',
      estimated: 'Estimeret tid',
      adjusted: 'Justeret',
      timeWindow: 'Tidsvindue',
      onCar: 'På bil',
      frequency: 'Hyppighed',
      startDate: 'Start dato',
      endDate: 'Slut dato (valgfrit)',
      from: 'Fra',
      to: 'Til',
      days: 'Dage',
      holidays: 'Helligdage',
      startdate: 'Start dato',
      enddate: 'Slut dato',
      orders: 'ordrer',
      id: 'ID',
      shipmentId: 'Forsendelsesnummer',
      packageId: 'Pakkenummer',
      errorMessage: 'Fejl',
      customer: 'Kunde',
      pickup: 'Opsamling',
      delivery: 'Levering',
      address: 'Adresse',
      zipcode: 'Postnummer',
      area: 'Område',
      weight: 'Vægt',
      volume: 'Volumen{{unit}}',
      qty: 'Antal',
      preplanned: 'Forudplanlagte',
      group: 'Gruppe',
      totalOrders: 'Samlet antal ordrer',
      ordersFailed: 'Ordrer mislykkedes',
      date: 'Dato',
      finished: 'Afsluttede',
      failed: 'Mislykkedes',
      ordersInvoiced: 'Fakturerede ordrer',
      pickupContactPerson: 'Opsamling Kontaktperson',
      pickupPhone: 'Opsamling telefon',
      deliveryContactPerson: 'Levering kontaktperson',
      deliveryPhone: 'Levering telefon',
      contactPerson: 'Kontaktperson',
      phone: 'Telefon',
      deliveryReferencePlaceholder: 'Modtagers reference',
      specificationDescriptionPlaceholder: 'Vareoplysninger',
      groupName: 'Gruppenavn',
      customerNumber: 'Kundenummer',
      orderNote: 'Notat',
      returned: 'Returneret',
      price: 'Pris',
      slotName: 'Rute',
      deliveryEtaSmsSentAt: 'Leverings ETA SMS sendt',
      trip: 'Rejse',
      addresses: {
        title: 'Adresser',
        search: 'Adressesøgning',
        searchAddresses: 'Søg adresser',
        filterAddresses: 'Filtrer adresser',
        deleteConfirmation: 'Er du sikker på du vil slette denne adresse?',
        deleteMultipleConfirmation: 'Er du sikker på du vil slette alle valgte adresser?',
        deleteSuccess: 'Adresse slettet',
        deleteMultipleSuccess: 'Adresser slettet',
        delete: 'Slet',
        name: 'Navn',
        phone: 'Telefonnummer',
        address: 'Adresse',
        zipArea: 'Område',
        zipCode: 'Postnummer',
        country: 'Land',
        contactPerson: 'Kontaktperson',
        instructions: 'Instruktioner',
        deleteAddress: 'Slet adresse',
        deleteMultipleAddresses: 'Slet adresser ({{count}})',
        loc: 'Lok',
        locTitle: 'Lokation'
      },
      orderGrid: { vehicleType: 'Køretøj', extraInfo: 'Ekstra info' },
      dispatch: {
        id: 'ID',
        address: 'Adresse',
        adjusted: 'Justeret',
        alreadyAssigned: '<< Allerede tildelt rute',
        customerName: 'Kundenavn',
        name: 'Navn',
        timeWindow: 'Tidsvindue'
      },
      orderSource: 'Ordrekilde',
      postalCodes: '# postnumre',
      displayEndCustomerPriceShd: 'Vis kundepris',
      courier: 'Bud',
      createdBy: 'Lavet af',
      createdAt: 'Lavet',
      plannedDeliveryDate: 'Planlagt dato',
      orderedDeliveryDate: 'Bestilt dato',
      lastUpdatedAt: 'Sidst opdateret til',
      route: 'Rute',
      instructions: 'Instruktioner',
      pickupDuration: 'PU varighed',
      deliveryDuration: 'DEL varighed',
      originalPickupAddress: 'Oprindelig afsenderadresse',
      originalPickupZipCode: 'Oprindelig afsenderpostnummer',
      originalPickupZipArea: 'Oprindelig afsenderby',
      originalPickupCountry: 'Oprindelig afsenderland',
      originalDeliveryAddress: 'Oprindelig modtageradresse',
      originalDeliveryZipCode: 'Oprindelig modtagerpostnummer',
      originalDeliveryZipArea: 'Oprindelig modtagerby',
      originalDeliveryCountry: 'Oprindelig modtagerland',
      packagesArrivedAtDip: 'Ankommet',
      packagesCollected: 'Afhentning',
      packagesDelivered: 'Levering',
      originalDeliveryEarliest: 'Oprindelig DEL',
      originalDeliveryLatest: 'Oprindelig DEL',
      preAdvices: 'Aviseringer',
      preAdviceScheduledDate: 'Avisering sendt ut',
      hasPreAdviceSettings: 'Aviseringsregler',
      arrivedAtHub: 'Ankommet HUB',
      returnToSender: 'Retur til afsendre',
      preAdviceFailed: 'Mislykkedes',
      deliveryAttempts: 'Leveringsforsøk'
    }
  },
  openBooking: {
    pickupReferencePlaceholder: 'Kundereference',
    customerContactPlaceholder: 'Bestilt af – synligt på fakturaen',
    invoiceInfoPlaceholder: 'Fakturainformation – synligt på fakturaen',
    pickupTimePlaceholder: 'Tidligste opsamlingstidspunkt'
  },
  instant: {
    title: 'Disponering',
    openMap: 'Åben kort',
    group: 'Gruppe valgt',
    editGroup: 'Rediger gruppe',
    groupName: 'Gruppe navn',
    groupOrders: 'Ordregruppe',
    viewGroup: 'Vis gruppe',
    ordersToGroup: 'Ordrer til gruppe',
    fixedOrder: 'Fast rækkefølge',
    paidTogether: 'Betalt sammen',
    saveGroup: 'Gem gruppe',
    ungroup: 'Ophæv gruppering',
    removeSelected: 'Fjern valgte',
    split: 'Opdel valgte',
    noCourierSelected: 'Intet bud valgt',
    noOrderSelected: 'Ingen ordre valgt',
    workingHours: 'Arbejdstimer',
    chatButton: 'Chat med chauffør',
    offeredShipments: 'Tilbudte forsendelser',
    rejectedShipments: 'Afviste forsendelser',
    rejectedShipmentsSnackbar: '{{count}} afvist forsendelse af "{{name}}"',
    rejectedShipmentsSnackbar_plural: '{{count}} afviste forsendelser af "{{name}}"',
    toBeDone: 'Opsamlinger og leveringer der skal udføres',
    finishedRoutePoints: 'Færdige opsamlinger og leveringer',
    suggestedRouteApplied: 'Den foreslåede rute er anvendt',
    suggestedRouteCancelled: 'Den foreslåede rute er blevet annulleret',
    reorderValidationError: 'Den foreslåede rækkefølge er ugyldig',
    planSlot: 'Ruteplan',
    columnSettings: {
      reset: 'Nulstil',
      settings: 'Indstillinger',
      hideColumns: 'Vis kolonner',
      saveAndClose: 'Gem og luk'
    },
    routePoint: { pickup: 'A', delivery: 'L' },
    confirmation: {
      heading: 'Ordrebekræftelse',
      from: 'Fra',
      to: 'Til',
      pickup: 'Opsamling',
      delivery: 'Levering',
      shipmentNumber: 'Forsendelsesnummer',
      copyOrder: 'Kopier ordre',
      editOrder: 'Rediger ordre'
    },
    goods: {
      custom: 'Brugerdefineret',
      letter: 'Brev',
      bag: 'Pose',
      smallbox: 'Lille kasse',
      mediumbox: 'Medium kasse',
      largebox: 'Stor kasse',
      tube: 'Rør',
      quarterpallet: 'Kvart palle',
      halfpallet: 'Halv palle',
      fullpallet: 'Palle',
      suitcase: 'Kuffert',
      movingbox: 'Flyttekasse',
      trolley: 'Vogn',
      parcel: 'Kolli',
      expressbox: 'Express boks'
    },
    instantGrid: {
      editColumnTooltip: 'Klik for at redigere',
      deleteColumnTooltip: 'Klik for at slette',
      unassignColumnTooltip: 'Klik for at fjerne tildelingen af forsendelsen',
      dragColumnTooltip: 'Klik og træk for at tildele forsendelsen til et bud',
      filtersActive: 'Filtre er aktive',
      clearFilters: 'Fjern alle filtre',
      filterPlaceholder: 'filtrer <enter>'
    },
    booking: {
      addPackage: 'Tilføj ny pakke',
      removePackageRow: 'Fjern pakke',
      cannotRemoveLastPackageRow: 'Den sidste pakke kunne ikke fjernes',
      copyPackageRow: 'Kopiér pakke',
      headerCreate: 'Ny booking',
      headerEdit: 'Rediger booking',
      headerCopy: 'Kopier booking',
      headerRestore: 'Gendan ordre',
      customer: 'Kunder',
      customerPlaceholder: 'Kunde id',
      invoiceSplitPlaceholder: 'Faktura split',
      invoiceSplitPlaceholderLegend: 'Velg faktura split',
      invalidSubcustomer: 'Subcustomer-navn er ugyldigt',
      shipmentOrPackageId: 'Forsendelses ID eller Pakke ID',
      sender: 'Afsender',
      setAsDefaultAddress: 'Indstil som standardadresse',
      defaultAddressUpdated: 'Standardadresse opdateret',
      recipient: 'Modtager',
      pickupNamePlaceholder: 'Navn',
      pickupAddressPlaceholder: 'Adresse',
      pickupZipCodePlaceholder: 'Postnummer',
      pickupZipAreaPlaceholder: 'By',
      pickupCountryPlaceholder: '-- Vælg land --',
      pickupPhoneNumberPlaceholder: 'Telefon',
      pickupContactPerson: 'Kontaktperson',
      customerReference: 'Kundereference',
      pickupReferencePlaceholder: 'Kundereference',
      pickupInformationPlaceholder: 'Afhentningsinformation',
      customerContact: 'Kontaktperson hos kunden',
      customerContactPlaceholder: '$t(instant.booking.customerContact) – Dette felt vises på fakturaen',
      customerInfoPlaceholder: 'General kundespecifik information',
      customerInfoHelper:
        'Denne information er forbundet til en kunde. Ændringer foretaget vil blive synlig på alle ordrer forbundet til kunden.<br><br>Brug ”note” feltet nedenfor, for at tilføje ordrespecifik information.',
      deliveryNamePlaceholder: 'Navn',
      deliveryAddressPlaceholder: 'Adresse',
      deliveryZipCodePlaceholder: 'Postnummer',
      deliveryZipAreaPlaceholder: 'By',
      deliveryCountryPlaceholder: '-- Vælg land --',
      deliveryPhoneNumberPlaceholder: 'Telefon',
      deliverySecondPhoneNumberPlaceholder: 'Phone 2',
      deliveryReferencePlaceholder: 'Modtagers reference',
      deliveryInformationPlaceholder: 'Leveringsinformation',
      dimensions: 'Gods',
      weightPlaceholder: 'Vægt (kg)',
      lengthPlaceholder: 'Længde (cm)',
      widthPlaceholder: 'Bredde (cm)',
      heightPlaceholder: 'Højde (cm)',
      serviceCode: 'Servicekode',
      servicePlaceholder: 'Servicekode',
      serviceSelect: 'Vælg service',
      serviceSelectDisabled: 'Ændring af tjenester på HD -ordrer udføres i HappyFlow',
      goodsEditDisabled: 'Ændringer af gods på HD -ordrer udføres i HappyFlow',
      customizedServices: '-- KUNDESPECIFIKKE SERVICES --',
      specificationDescription: 'Gods',
      specificationDescriptionPlaceholder: 'Vareoplysninger',
      invoiceInfoPlaceholder: 'Fakturainformation – Dette felt er synligt på kundefakturaen',
      manualInvoiceInfoPlaceholder:
        'Manuel fakturahåndtering (Hvis dette felt er udfyldt med faktureringsinstruktioner, faktureres ordren ikke automatisk)',
      pickup: 'Afhentning',
      delivery: 'Levering',
      pickupDatePlaceholder: 'Afhentningsdato',
      pickupTimePlaceholder: 'Afhentningstid',
      deliveryDatePlaceholder: 'Leveringsdato',
      deliveryTimePlaceholder: 'Leveringstid',
      deliveryContactPerson: 'Kontaktperson',
      restoreButton: 'Gendan',
      orderButton: 'Bestil',
      editButton: 'Opdater',
      copyButton: 'Bestil',
      created: 'Ordren er registreret',
      edited: 'Ordren er opdateret',
      quickSelect: 'Hurtig valg',
      volume: 'Volumen',
      totalVolume: 'Total volumen {{volume}}',
      totalWeight: 'Total vægt {{weight}}',
      removeItem: 'Fjern',
      copyItem: 'Kopiere',
      addItem: 'Tilføj',
      calculatePrice: 'Beregn pris',
      priceExplanations: {
        header: 'Prisforklaring',
        article: 'Artikel: {{article}}',
        priceListName: 'Prisliste:: {{pricelist}}',
        explanations: 'Forklaringer:',
        noInfo: 'Ingen prisoplysninger tilgængelige'
      },
      approveIncompletePrice: 'Jeg godkender alle rækker med nul pris',
      prePlannedToCourier: 'Vælg bud',
      automaticDepartment: 'Automatisk',
      departmentLabel: 'Afdeling',
      editIndicator: 'Rediger',
      updateIndicator: 'Opdater',
      copyIndicator: 'Kopier',
      quantity: 'Mængde',
      additionalServices: 'Ekstra services',
      paymentInformation: 'Betalingsoplysninger',
      paidTogetherLabel: 'Betalt sammen?',
      groupingLabel: 'Forsendelsesgruppering',
      groupIdPlaceholder: 'Unik gruppe id',
      groupOrderPlaceholder: 'Grupperet forsendelsesrækkefølge',
      addRecipient: 'Tilføj flere modtagere',
      addPickup: 'Tilføj flere afsendere',
      vehicleType: 'Køretøjstype',
      vehicleTypeUpdated: 'Køretøjstype er opdateret',
      selectVehicleType: 'Vælg køretøjstype',
      customerPrice: 'Kundepris',
      resourcePrice: 'Unitpris',
      priceList: 'Prisliste',
      manualPrice: 'Manuel',
      vehicleTypes: {
        car: 'Bil',
        bicycle: 'Cykel',
        truck: 'Lastbil',
        van: 'Varebil',
        motorcycle: 'Motorcykel',
        cargobike: 'Lastcykel'
      },
      date: 'Dato',
      unNumber: 'UN-nummer',
      hazardous: 'Klasse',
      group: 'Gruppe',
      netWeight: 'Nettovægt',
      name: 'Navn',
      restrictions: 'Restriktioner',
      totalPoints: 'Samlet antal point'
    },
    courierSorting: { title: 'Sorter efter', alystraId: 'Unit id', empty: 'Tom', full: 'Fuld' },
    courierFilter: { title: 'Filtrer bude efter', all: 'Vis alle', inactive: 'Skjul inaktive' },
    shipmentFilter: {
      title: 'Filtrer forsendelser',
      showAll: 'Vis alle',
      notCollected: 'Ikke afhentet',
      hideAll: 'Skjul alle'
    },
    offer: {
      carEmpty: 'Bilen er på nuværende tidspunkt tom',
      suggestedDelivery: 'Foreslået leveringsrækkefølge',
      newShipments: 'Ny forsendelse',
      manualRoute: 'Tvungen rute',
      confirmRoute: 'Bekræft rute',
      assigning: 'Tildeling',
      shipmentsTo: 'forsendelser til',
      shipmentTo: 'forsendelse til'
    },
    useFlexible: 'Brug fleksible tider',
    forceOptimizing: 'Tving optimering af rækkefølge',
    replanRoute: 'Omplanlæg rute',
    recurring: {
      title: 'Tilbagevendende',
      successMessage: '{{number}} forsendelse(r) blev tilføjet til valgte gentagelsesgrupper',
      recurringOrders: 'Tilbagevendende ordrer',
      includedOrders: 'Ordrer indgår i tidsplanen',
      details: 'Detaljer',
      editOriginal: 'Rediger original',
      copyOriginal: 'Kopier original',
      notStarted: 'Ikke startet',
      executing: 'Kører...',
      ended: 'Færdigt',
      pending: 'Resterne',
      total: 'Samlet',
      failedOrders: 'Mislykkede ordre',
      name: 'Navn',
      frequency: 'Hyppighed',
      frequencies: { weekly: 'Hver uge', second_week: 'Hver anden uge', third_week: 'Hver tredje uge' },
      daysOfWeek: 'Dage om ugen',
      includeHolidays: 'Inkluder helligdage',
      startDate: 'Start dato',
      endDate: 'Slut dato (valgfrit)',
      save: 'Gem',
      fromDate: 'Fra dato',
      confirmDelete: 'Er du sikker på, at du vil slette de/den valgte ordre(r)?',
      from: 'Fra',
      createOrders: 'Opret ordrer',
      showResult: 'Vis resultat',
      startdate: 'Start dato',
      enddate: 'Slut dato',
      orders: 'ordrer',
      failed: 'Mislykkedes',
      by: 'Af',
      on: 'den',
      reset: 'nulstil',
      confirmReset: 'Vil du nulstille gentagne ordrer for {{date}}?'
    },
    corrections: {
      title: 'Rettelser',
      headline: 'Ordrerettelser for {{date}}',
      accepted: 'Ordrerettelser accepteret',
      rejected: 'Ordrerettelser afvist',
      approve: 'Godkend',
      decline: 'AFVIS',
      shipmentId: 'Forsendelses Id',
      courier: 'Bud',
      from: 'Fra',
      to: 'Til',
      details: 'Detaljer',
      pickupWaitingTime: 'Ventetid ved opsamling',
      deliveryWaitingTime: 'Ventetid ved levering',
      weight: 'Vægt',
      volume: 'Volumen',
      packages: 'Pakker',
      serviceCode: 'Servicekode'
    },
    suggestedUnits: {
      unitName: 'Enhedsnavn',
      driverName: 'Førerens navn',
      slotName: 'Rute',
      timeAtLocation: 'Tid på placering',
      distanceFromLocation: 'Afstand fra lokation',
      shipmentInformation: 'Forsendelsesinformation',
      suggestedVehicles: 'Foreslåede køretøjer',
      suggestedVehiclesOnPickup: 'Foreslåede køretøjer ved afhentning',
      suggestedVehiclesOnDelivery: 'Foreslåede køretøjer ved levering',
      suggestedUnits: 'Foreslåede enheder',
      noSuggestionsAvailable: 'Ingen tilgængelige forslag',
      pickupTime: 'Afhentningstid',
      deliveryTime: 'Leveringstid',
      filterBy: 'Sorter efter'
    }
  },
  errors: {
    DEPARTMENT_INVALID_ALYSTRA_ID: 'Ugyldig Alystra id',
    ENTITY_DOES_NOT_EXIST: 'Enhed findes ikke',
    INVALID_DATE_RANGE: 'Ugyldigt datointerval',
    INVALID_DELIVERY_LATITUDE: 'Ugyldig leveringsbreddegrad',
    INVALID_DELIVERY_LONGITUDE: 'Ugyldig leveringslængdegrad',
    INVALID_LATITUDE: 'Ugyldig breddegrad',
    INVALID_LONGITUDE: 'Ugyldig længdegrad',
    INVALID_OR_MISSING_TIME: 'Ugyldig eller manglende tid',
    INVALID_PHONE_NUMBER: 'Ugyldigt telefonnummer',
    DUPLICATE_PHONE_NUMBER: 'Duplikeret telefonnummer!',
    INVALID_PICKUP_LATITUDE: 'Ugyldig afhentningsbreddegrad',
    INVALID_PICKUP_LONGITUDE: 'Ugyldig afhentningslængdegrad',
    INVALID_SLOT_TYPE: 'Ugyldig rutetype',
    MISSING_ADDRESS: 'Manglende adresse',
    MISSING_ALYSTRA_ID: 'Manglende alystra id',
    MISSING_AREA: 'Manglende område',
    MISSING_CAPACITY: 'Manglende kapacitet',
    MISSING_COLOR: 'Manglende farve',
    MISSING_CONSIGNMENT_INPUTS: 'Manglende forsendelsesindlæsning',
    MISSING_CONSIGNMENT: 'Manglende forsendelse',
    MISSING_CONSIGNMENTS: 'Manglende forsendelser',
    MISSING_COURIER_ID: 'Manglende bud id',
    MISSING_COURIER: 'Du skal vælge et bud',
    MISSING_CREATED_AT: 'Manglende oprettelsestid',
    MISSING_CUSTOMER_ID: 'Manglende kunde id',
    MISSING_CUSTOMER_REF: 'Manglende kundereference',
    MISSING_DATE: 'Manglende dato',
    MISSING_DAYS: 'Manglende dage',
    MISSING_DEPARTMENT: 'Manglende afdeling',
    MISSING_DESCRIPTION: 'Manglende instruktioner',
    MISSING_DEVIATION_TEXT: 'Manglende afvigelsestekst',
    MISSING_END_LOCATION_LATITUDE: 'Manglende slut lokationsbreddegrad',
    MISSING_END_LOCATION_LONGITUDE: 'Manglende slut lokationslængdegrad',
    MISSING_EVENT_DATA: 'Manglende event data',
    MISSING_EVENT_TYPE: 'Manglende event type',
    MISSING_FILE_NAME: 'Filnavn mangler',
    MISSING_FROM_DATE: 'Mangler fra dato',
    MISSING_ID: 'Manglende id',
    MISSING_LOCATION_LATITUDE: 'Manglende lokationsbreddegrad',
    MISSING_LOCATION_LONGITUDE: 'Manglende lokationslængdegrad',
    MISSING_LOCATION_POSITION: 'Manglende lokations position',
    MISSING_LOCATION_TIME: 'Manglende lokations tid',
    MISSING_NAME: 'Manglende navn',
    MISSING_GROUP: 'Manglende gruppering',
    MISSING_PACKAGE_ID: 'Manglende pakkeid',
    MISSING_PHONE: 'Manglende telefon',
    MISSING_PLANNER_ID: 'Manglende planlæggerid',
    MISSING_SERVICE_CODE: 'Manglende servicekode',
    MISSING_SHIPMENT_ID: 'Manglende shipment id',
    MISSING_SLOT_ID: 'Manglende rute id',
    MISSING_SLOT_TYPE: 'Manglende rute type',
    MISSING_SMS_TOKEN: 'Manglende sms kode',
    MISSING_START_LOCATION_LATITUDE: 'Manglende breddegrad på start lokation',
    MISSING_START_LOCATION_LONGITUDE: 'Manglende længdegrad på start lokation',
    MISSING_TO_DATE: 'Manglende til dato',
    MISSING_ZIP_AREA: 'Manglende postområde',
    MISSING_ZIP_CODE: 'Manglende postnummer',
    MISSING_COUNTRY_CODE: 'Manglende landekode',
    NO_SUCH_USER: 'Bruger findes ikke',
    PARAM_NOT_OF_EXPECTED_TYPE: 'Parameter er ikke af forventet type',
    PARAM_NULL_NOT_ALLOWED: 'Parameter nul er ikke tilladt',
    QUERY_NAME_MISSING: 'Query navn mangler',
    QUERY_PARAMS_MISSING: 'Query parameter mangler',
    USER_NOT_EXIST_OR_INCORRECT_ROLE: 'Brugeren findes ikke eller har forkert rolle',
    USER_EXIST_BUT_INCOMPATIBLE_ROLE: 'Brugeren findes allerede og har en anden rolle',
    SLOT_CANNOT_BE_STARTED: 'Ruten kan ikke startes',
    SLOT_CANNOT_BE_DELETED: 'Ruten kan ikke slettes',
    COURIER_NOT_PART_OF_DEPARTMENT: 'Buddet er ikke en del af afdelingen',
    CONSIGNMENT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Forsendelser med forkert status kan ikke oprettes',
    ORDER_DOES_NOT_EXISTS: 'Ordren findes ikke',
    OPTIMIZE_ALREADY_RUNNING: 'Optimeringen kører allerede',
    CANNOT_CHANGE_ORDER: 'ordren kan ikke ændres',
    NO_ACCESS: 'Ingen adgang',
    NO_RELEVANT_CONSIGNMENTS_OR_SLOTS: 'Ingen relevante forsendelser til den givne rute',
    NO_RELEVANT_CONSIGNMENTS: 'Ingen relevante forsendelser til den givne rute',
    NO_RELEVANT_SLOTS: 'Kun ruter, der ikke er startet eller er sat på pause, kan bruges til denne optimering',
    NOT_EQUAL_PICKUP_ADDRESSES:
      'Alle afhentningsadresser skal være ens for at begrænse alle forsendelser til én chauffør',
    NO_SUCH_QUERY: 'Ingen sådan forespørgsel',
    EXCEL_MISSING_SHIPMENT_ID: 'Manglende forsendelses-id i celle {{col}} på række {{row}}',
    EXCEL_MISSING_CUSTOMER_REF: 'Manglende kundereference i celle {{col}} på række {{row}}',
    EXCEL_MISSING_CUSTOMER_ID: 'Manglende kunde-id i celle {{col}} på række {{row}}',
    EXCEL_MISSING_DEPARTMENT: 'Manglende afdeling i celle {{col}} på række {{row}}',
    EXCEL_MISSING_PACKAGE_ID: 'Manglende pakke-id i celle {{col}} på række {{row}}',
    EXCEL_MISSING_NAME: 'Manglende navn i celle {{col}} på række {{row}}',
    EXCEL_INVALID_PHONE: 'Ugyldigt telefonnummer i celle {{col}} på række {{row}}',
    EXCEL_MISSING_ADDRESS: 'Manglende adresse i celle {{col}} på række {{row}}',
    EXCEL_MISSING_ZIP_AREA: 'Manglende postnummer i celle {{col}} på række {{row}}',
    EXCEL_MISSING_ZIP_CODE: 'Manglende by i celle {{col}} på række {{row}}',
    EXCEL_MISSING_FROM_DATE: 'Manglende fra dato i celle {{col}} på række {{row}}',
    EXCEL_MISSING_TO_DATE: 'Manglende til dato i celle {{col}} på række {{row}}',
    EXCEL_INVALID_FROM_DATE: 'Fra datoen kan ikke være før i dag. Fundet i celle {{col}} på række {{row}}',
    EXCEL_INVALID_TO_DATE: 'Til datoen kan ikke være før i dag. Fundet i celle {{col}} på række {{row}}',
    EXCEL_MISSING_NOTIFICATION_SMS: 'Manglende adviserings SMS i celle {{col}} på række {{row}}',
    EXCEL_MISSING_SIZE: 'Manglende størrelse i celle {{col}} på række {{row}}',
    EXCEL_MISSING_ORDERS: 'Ingen ordre er fundet i regnearket.',
    EXCEL_MISSING_COUNTRY: 'Manglende landekode eller navn i celle {{col}} på række {{row}}',
    EXCEL_NO_ACCESS_DEPARTMENT: 'Ingen adgang til afdeling"{{dept_ref}}" some r fundet i række {{row}}',
    EXCEL_MISSING_DESCRIPTION: 'Manglende beskrivelse i celle {{col}} på række {{row}}',
    EXCEL_MISSING_PICKUP_INSTRUCTIONS: 'Manglende afhentningsinstruktioner i celle {{col}} på række {{row}}',
    EXCEL_MISSING_DELIVERY_INSTRUCTIONS: 'Manglende leveringsinstruktioner celle {{col}} på række {{row}}',
    MISSING_PICKUP_LOCATION_AND_TIME: 'Manglende opsamlingslokation og tid',
    MISSING_EARLIEST_PICKUP_DATE: 'Tidligste afhentningsdato mangler',
    MISSING_LATEST_PICKUP_DATE: 'Seneste afhentningsdato mangler',
    MISSING_EARLIEST_DELIVERY_DATE: 'Tidligste leveringsdato mangler',
    MISSING_LATEST_DELIVERY_DATE: 'Seneste afhentnings dato mangler',
    INVALID_EARLIEST_PICKUP_DATE: 'Ugyldig afhentningsdato',
    INVALID_LATEST_PICKUP_DATE: 'Ugyldig afhentningsdato',
    INVALID_EARLIEST_DELIVERY_DATE: 'Ugyldig leveringssdato',
    INVALID_LATEST_DELIVERY_DATE: 'Ugyldig leveringssdato',
    CANNOT_MODIFY_ORDERS_FROM_DIFFERENT_SOURCE: 'Kan ikke ændre API-ordrer fra Excel',
    PICKUP_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST: 'Opsamlingstiden skal være før leveringstiden',
    DELIVERY_EARLIEST_SHOULD_BE_BEFORE_DELIVERY_LATEST:
      'Tidligste leveringstidspunkt skal være før seneste leveringtidspunkt',
    CANNOT_DELIVER_ON_A_HOLIDAY: 'Kan ikke levere på helligdage',
    MISSING_TEMPLATE_ID: 'Manglende skabelon-id',
    TEMPLATE_DOES_NOT_EXIST: 'Skabelonen findes ikke',
    TEMPLATE_CANNOT_BE_DELETED: 'Skabelonen kan ikke slettes',
    MISSING_TEMPLATE_SLOTS: 'Skabelonen mangler ruter',
    TEMPLATE_NAME_ALREADY_EXISTS: 'En skabelon med dette navn findes allerede',
    COURIER_ALREADY_EXISTS: 'Der findes allerede et bud med dette telefonnummer',
    ORDER_EXIST_FOR_SERVICE: 'Kan ikke slette service, da der findes ordre med denne service',
    NO_SERVICE_FOUND: 'Tilføj en service, før du tilføjer en kunde',
    CANNOT_DELETE_AS_SERVICE_EXIST: 'Fjern tilknytningen til kunden, før du sletter servicen',
    SLOT_IN_INCORRECT_STATE_CANNOT_UPDATE: 'Ruten har ikke en gyldig status til denne ændring',
    GRAPHHOPPER_UNASSIGNED: 'Kunne ikke tildele alle forsendelser (pga. tid, kapacitet eller andre faktorer)',
    GRAPHHOPPER_REQUEST_FAILED: 'Anmodningen om optimering af ruten mislykkedes',
    GRAPHHOPPER_LATEST_ARRIVAL_AT_DELIVERY_AFTER_EARLIEST_DEPARTURE:
      'Seneste ankomst for levering kan ikke være efter tidligste afgang – Er tidsvinduet passeret?',
    GRAPHHOPPER_EARLIEST_GREATER_THAN_LATEST:
      'Tidligste tid kan ikke være senere end seneste tid – Er nogle af tiderne i fortiden?',
    GRAPHHOPPER_PICKUP_EARLIEST_AFTER_LATEST:
      'Afhentnings tidsvindue for forsendelse {{shipmentId}} kan ikke være i fortiden ({{pickupEarliest}} - {{pickupLatest}})',
    GRAPHHOPPER_DELIVERY_EARLIEST_AFTER_LATEST:
      'Leverings tidsvindue for forsendelse {{shipmentId}} kan ikke være i fortiden ({{deliveryEarliest}} - {{deliveryLatest}}',
    SLOT_CANNOT_BE_EDITED: 'Ruten er ikke i en gyldig status for opdateringen',
    INVALID_POSTCODE_AND_COUNTRY_CODE_CANNOT_BE_HANDLED: 'Ugyldig postnummer og landekode',
    INVALID_BASIC_SERVICE_CODE: 'Ugyldig basis servicekode',
    INVALID_PICKUP_DATE_EARLIEST_FORMAT: 'Ugyldig format af tidligste afhentningsdato',
    INVALID_PICKUP_DATE_LATEST_FORMAT: 'Ugyldig format af seneste opsamlingsdato',
    INVALID_DELIVERY_DATE_LATEST_FORMAT: 'Ugyldig format af seneste leveringsdato',
    INVALID_DELIVERY_DATE_EARLIEST_FORMAT: 'Ugyldig format af tidligste leveringsdato',
    INVALID_POSTAL_CODE: 'Ugyldig postnummer',
    INVALID_MEASUREMENT_UNIT: 'Ugyldig måleenhed',
    MISSING_CONSIGNMENT_SET: 'Manglende forsendelses sæt',
    MISSING_UPDATE_INDICATOR: 'Manglende opdateringsindikator',
    MISSING_CONSIGNMENT_ID: 'Manglende forsendelses-id',
    MISSING_REFERENCE_NO: 'Manglende reference-id',
    MISSING_PACKAGE: 'Manglende pakke',
    MISSING_DELIVERY_DATE_EARLIEST: 'Tidligste leveringsdato mangler',
    MISSING_DELIVERY_DATE_LATEST: 'Seneste leveringsdato mangler',
    MISSING_CONSIGNEE: 'Manglende modtager',
    MISSING_CONSIGNOR: 'Manglende afsender',
    MISSING_POSTAL_CODE: 'Manglende postnummer',
    MISSING_CITY: 'Manglende by',
    MISSING_PICKUP_DATE_LATEST: 'Seneste opsamlingssdato mangler',
    MISSING_PICKUP_DATE_EARLIEST: 'Tidligste opsamlingsdato mangler',
    MISSING_CONSIGNOR_PARTY_ID: 'Mangler afsender-ID',
    INVALID_CUSTOMER_NO_MAPPING: 'Ugyldig kundenummer mapping',
    INVALID_ROUTING: 'Ugyldig rute',
    NO_SERVICE_MATCHING_SERVICE_CODE: 'Ingen service matcher servicekoden',
    NO_SERVICE_MATCHING_DAY_OF_WEEK: 'Ingen service matcher ugedagen',
    NO_SERVICE_MATCHING_PICKUP_AND_DELIVERY_TIMES: 'Ingen service matcher afhentning og leveringstider ',
    ORDER_WAS_AFTER_CUTOFF_TIME: 'Ordrer er efter deadline',
    OPTIMUS_SERVICE_MUST_HAVE_PICKUP_AND_DELIVERY_TIMES: 'Skal have opsamlings og leveringstid',
    ORDER_CANNOT_BE_MOVED: 'Forsendelsen kan ikke flyttes, den må ikke tildeles en rute',
    MISSING_DESTINATION_DEPARTMENT_ID: 'Manglende destinationsafdeling',
    SHIPMENT_NOT_FOUND: 'Fandt ikke forsendelsen',
    DUPLICATE_EXCEL_IMPORT:
      'Det samme filnavn er allerede importeret til denne afdeling i dag. Du kan se resultaterne af denne import <0>her</0>. Skift filnavn, hvis du vil importere igen.',
    SHIPMENT_INVALID_QUERY:
      'I peak perioden vil søgning være begrænset for at sikre god ydeevne. Du kan søge alle ordrer for en dag, eller tilføje søgeparameter.',
    DURATION_IS_MORE: 'Du skal begrænse din søgning til {{maxDuration}} dage.',
    ADDRESS_CANNOT_BE_DELETED: 'Adressen bruges som standardadresse og kan ikke slettes.',
    UNKOWN_ERROR: 'Ændringer kunne ikke gemmes',
    INCORRECT_STATES_OR_ASSIGNED_TO_SLOT: 'Forkerte tilstande eller tildelt til slot',
    CANNOT_MOVE_ORDERS: 'Kan ikke flytte ordrer',
    DESTINATION_DEPARTMENT_INELIGIBLE: 'Destination afdeling ikke berettiget',
    DEPARTMENT_MISSING_ADDRESS_DETAILS: 'Afdeling mangler adresseoplysninger',
    MISSING_DELIVERY_PHONE_NUMBER: 'Telefonnummer mangler for',
    INVALID_DELIVERY_PHONE_NUMBER: 'Telefonnummeret er ikke gyldigt for',
    ROLE_IN_USE: 'Rollen kan ikke fjernes, da rollen er i brug.',
    INVALID_NAME: 'Rollenavnet skal være unikt. En rolle med dette navn findes allerede.',
    TEXT_CANNOT_BE_DELETED:
      'Teksten bruges på en tjeneste og kan ikke slettes. Afbryd teksten fra tjenesten for at slette den.'
  },
  customer: {
    showAll: 'Vis alle kunder',
    customer: 'Kunde',
    groups: {
      title: 'Kundegrupper',
      name: 'Gruppenavn',
      titleWithCount: '{{count}} kundegrupper',
      description: 'Beskrivelse',
      numberOfCustomers: 'Antal kunder',
      none: 'Ingen kundegrupper',
      new: 'Oppræt kundegruppe',
      edit: 'Rediger kundegruppe',
      searchCustomer: 'Søg kunde',
      members: 'Kunder i gruppen',
      save: 'Gem',
      deleteConfirm: 'Er du sikker på, du vil slette gruppen?',
      deleted: 'Kundegruppen er slettet.',
      cannotDelete: 'Kundegruppen kan ikke slettes fordi den bruges i en aviseringsregel.'
    },
    customerTitle: 'Kunder',
    customerNo: 'Kunde Nr.',
    name: 'Navn',
    emailToSender: 'E-mail til afsender',
    deviations: 'Afvigelser',
    add: 'Tilføj',
    noOrders: 'Ingen ordre i Glow',
    noOrdersDescription: 'De kunder, du har adgang til, har ingen ordrer i Glow',
    choose: 'Vælg en kunde',
    findCustomer: 'Find en kunde',
    customerPlaceHolder: 'Søg efter kunder',
    emailRecipients: 'E-mail til modtager',
    smsRecipients: 'Sms til modtager',
    addEditEmails: 'Tilføj/rediger e-mails',
    addEditSms: 'Tilføj/rediger sms',
    addEditDeviations: 'Tilføj/rediger afvigelser',
    search: 'Søg',
    startTypingPlaceHolder: 'Start med at skrive',
    AddEdit: 'Tilføj/Rediger',
    searchHint:
      'Søg efter forsendelse, baseret på forsendelsesnummer, kundereference, pakkenummer, modtagers navn eller adresse',
    customerNumber: 'Kundenummer',
    senderName: 'Afsendernavn',
    addSenderName: 'Tilføj afsendernavn',
    senderNameAdded: 'Afsendernavn tilføjet',
    addInvoiceHDOrdersFromDate: 'Tilføj start dato for fakturering af HD-ordre i Glow',
    invoiceHDOrdersFromDate: 'Start dato for fakturering af HD-ordre i Glow',
    invoiceHDOrdersFromDateAdded: 'Start dato for fakturering af HD-ordre ændret',
    billingCustomerNumber: 'Betalende Kundenummer',
    addBillingCustomerNumber: 'Tilføj Betalende Kundenummer',
    billingCustomerNumberAdded: 'Betalende Kundenummer Tilføjet',
    searchPage: {
      fromDate: 'Fra dato',
      toDate: 'Til dato',
      today: 'I dag',
      tomorrow: 'I morgen',
      yesterday: 'I går',
      last30days: 'Seneste 30 dage',
      plusMinus15Days: '+/- 15 dage',
      plusMinus30Days: '+/- 30 dage'
    },
    smsText: { title: 'SMS Text', addEditSmsText: 'Add/ Edit SMS text', smsTypeTexts: { delivery: 'Levering SMS' } },
    showNotCollected: 'Ikke opsamlet',
    booking: {
      pickupReady: 'Opsamling klar',
      pickup: 'Opsamling',
      delivery: 'Levering',
      priceComment:
        'Du vil blive faktureret i henhold til din kundeaftale med Bring. Gebyrer er ikke inkluderet i denne pris.',
      recipientPrice: 'Modtagerpris:',
      customerContactPlaceholder: 'Bestilt af – Dette felt er synligt på kundefakturaen'
    },
    email: {
      emailTitle: 'E-mail til kunde',
      emailAddress: 'E-mail adresse',
      returnToSender: 'Retur til afsender',
      reminderToSender: 'Påmindelse til afsender',
      save: 'Gem',
      edit: 'Rediger',
      delete: 'Slet',
      emailExists: 'E-mail eksisterer allerede',
      invalidEmail: 'Ugyldig e-mail',
      true: 'Ja',
      false: 'Nej',
      notificationType: { hdPreAdvice: 'HD preavisering' },
      informationToCustomer: 'Information til kunde',
      returnToCustomer: 'Retur til kunde',
      addEmailToList: 'Tilføj e-mail til liste',
      registeredEmailSubscription: 'Registreret e-mailabonnement',
      updateEmail: 'Opdater e-mail',
      noRegisteredEmails: 'Ingen registrerede e-mails',
      detailsSavedSuccessfully: 'E-mail til afsender blev gemt med succes'
    },
    customerDeviations: {
      new: 'Tilføj ny afvigelse',
      deviationTitle: 'Afvigelser for kunder',
      addDeviation: 'Tilføj ny afvigelse',
      editDeviation: 'Rediger afvigelse',
      reason: 'Årsag',
      submit: 'Indsend',
      deviationCode: 'Afvigelseskoder',
      deviation: 'Afvigelse',
      deviationType: 'Afvigelsestype',
      description: 'Beskrivelse',
      code: 'Kode',
      edit: 'Rediger',
      delete: 'Slet',
      deviationAdded: 'Afvigelse tilføjet',
      deviationEdited: 'Afvigelse redigeret',
      deviationExists: 'Afvigelsen eksisterer allerede',
      deviationDeleted: 'Afvigelsen blev slettet'
    },
    customerInfo: 'Kunde Info',
    customerName: 'Kundenavn',
    invoicingAddress: 'Faktureringsadresse',
    organisationNumber: 'Organisationsnummer',
    visitingAddress: 'Besøgsadresse',
    creditBlocked: 'Kredit spærret',
    creditBlockedFrom: 'Kredit spærret fra dato',
    creditBlockedFromInfo: 'Bloker kredit for denne kunde fra en valgt dato',
    from: 'Fra',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'Sted',
    country: 'Land',
    edit: 'Rediger {{name}}',
    selectCountry: 'Vælg land',
    addNewCustomer: 'Tilføj ny kunde',
    bookingPageModalHeading: 'Booking side i Kundeportalen',
    bookingPageModalDescription:
      'Slå booking fra, hvis kunden ikke skal kunne booke forsendelser på dette kundenummer i Glow kundeportal.',
    bookingActive: 'Til',
    bookingInactive: 'Fra',
    brandedTrackingActive: 'Til',
    brandedTrackingInactive: 'Fra',
    bookingPageAvailable: 'Til',
    bookingPageHidden: 'Fra'
  },
  serviceMapping: {
    serviceName: 'Service navn',
    pickupTime: 'Opsamlingstid',
    deliveryTime: 'Leveringstid',
    internalName: 'Internt navn',
    internalDescription: 'Intern beskrivelse',
    externalName: 'Eksternt navn',
    externalDescription: 'Ekstern beskrivelse',
    alystraId: 'Alystra-Id',
    allowFlexDelivery: 'Tillad Flex levering',
    timeWindow: 'Tidsvindue',
    courierApp: 'Bud-app',
    allowCourierDescription: 'Tillad budet at acceptere eller afvise forsendelser med denne servicekode',
    allowCourierCheckbox: 'Ja, budet skal have tilladelse til det',
    serviceDeliveryType: 'Tjenestetype',
    noServiceDeliveryTypeSelected: 'Ikke angivet',
    serviceDeliveryTypes: {
      Delivery: 'Levering',
      Return: 'Retur',
      PickupPoint: 'Afhentningssted'
    }
  },
  feedback: { message: 'Giv os venligst nogle forslag til hvordan vi kan gøre denne side bedre.' },
  texts: {
    title: 'Tekst',
    update: 'Opdate tekst',
    group: 'Gruppering',
    edit: 'Rediger',
    delete: 'Slet',
    add: 'Tilføj tekst',
    reset: 'Nulstil',
    confirmDelete: 'Er du sikker på, du vil slette teksten?',
    added: 'Tekst tilføjet',
    deleted: 'Tekst slette',
    updated: 'Tekst opdateret',
    nameToolTip: 'Navn vises, når tekst linkes til tjenesten'
  },
  countries: { norway: 'Norge', sweden: 'Sverige', denmark: 'Danmark', germany: 'Tyskland' },
  shipmentsExport: {
    unit: 'Enhed',
    exportButton: 'Eksporter til Excel',
    shipmentId: 'Forsendelsesnummer',
    returned: 'Returneret',
    department: 'Afdeling',
    customer: 'Kunde',
    customerNumber: 'Kundenummer',
    date: 'Dato',
    pickupEarliest: 'Tidligste opsamlingstidspunkt',
    pickupLatest: 'Seneste opsamlingsstidspunkt',
    deliveryEarliest: 'Tidligste leveringstid',
    deliveryLatest: 'Seneste leveringstid',
    additionalServices: 'Ekstra services',
    pickupName: 'Opsamling',
    pickupAddress: 'Opsamlingsadresse',
    pickupZipCode: 'Opsamlingspostnummer',
    pickupZipArea: 'Opsamlingssted',
    pickupCoordinates: 'Opsamlingskoordinater',
    pickupInstructions: 'Opsamlingsinstruktioner',
    deliveryName: 'Levering',
    deliveryAddress: 'Leveringsadresse',
    deliveryZipCode: 'Leveringspostnummer',
    deliveryZipArea: 'Leveringsby',
    deliveryCoordinates: 'Leveringskoordinater',
    deliveryInstructions: 'Leveringsinstruktioner',
    quantity: 'Mængde',
    weight: 'Vægt',
    volume: 'Volumen',
    customerRef: 'Kundereference',
    pickupContactPerson: 'Opsamling kontaktperson',
    pickupPhone: 'Opsamling telefon',
    deliveryContactPerson: 'Levering kontaktperson',
    deliveryPhone: 'Levering telefon',
    deliveryEmail: 'Levering e-mail',
    recipientRef: 'Modtagers reference',
    price: 'Pris',
    group: 'Gruppe',
    pickupTime: 'Afhentet/ Forsøg',
    delivered: 'Leveret/ Forsøg',
    deliveryEtaSmsSentAt: 'Leverings ETA SMS sendt',
    courierName: 'Bud',
    goodsInfo: 'Vareoplysninger',
    note: 'Notat',
    slotName: 'Rute',
    estimatedArrivalTime: 'Estimeret',
    orderSource: 'Ordrekilde'
  },
  importExport: {
    dropText: 'Dra fil hit for å laste opp',
    export: 'Eksporter',
    exportAddresses: 'Eksporter kundeadresser til Excel',
    importExcel: 'Importer fra Excel',
    imported: 'adresser importeret',
    noRecipients: 'Ingen gyldige modtagere ble funnet',
    or: 'eller',
    recipients: 'modtagere',
    selectFile: 'Velg fil',
    uploadingFile: 'Uploader...'
  },
  customMeasurements: {
    name: 'Navn',
    weight: 'Vægt',
    height: 'Højde',
    length: 'Længde',
    width: 'Bredde',
    heading: 'Tilpassede godstyper',
    error: 'Fejl ved lagring af godstype: ',
    confirmDelete: 'Er du sikker på du vil slette denne godstype?',
    new: 'Lav ny',
    customizeGoodsTypes: 'Tilpas godstyper',
    customGoodsTypes: 'Tilpassede godstyper',
    defaultGoodsTypes: 'Standard godstyper',
    invalidName: 'Navn skal indeholde minimum 1 bogstav'
  },
  unresolved: {
    unresolvedAddresses: 'Uløste adresser',
    allResolved: 'Alle adresser er løst!',
    address: 'Adresse',
    noOfShipments: 'Antal forsendelser',
    shipment: 'forsendelse',
    shipment_plural: 'forsendelser',
    shipmentNumber: 'Forsendelsesnummer',
    customerRef: 'Kundereference',
    senderName: 'Afsendernavn',
    senderPhone: 'Afsenders telefonnummer',
    recipientName: 'Modtagers navn',
    recipientPhone: 'Modtagerens telefonnummer',
    searchAndSetLocation: 'Søg og vælg lokation',
    setALocationToContinue: 'Vælg lokation og fortsæt',
    resolveAddress: 'Løs adresse',
    suggestedAddressFound: 'Foreslået adresse fundet',
    thereIsOneUnresolvedAddress: 'Der er {{count}} uløst adresse',
    thereIsOneUnresolvedAddress_plural: 'Der er {{count}} uløste adresser'
  },
  optimizing: {
    reqTime: 'Anmodningstid',
    respTime: 'Responstid',
    department: 'Afdeling',
    userName: 'Bruger',
    request: 'Anmodning',
    response: 'Respons',
    error: 'Fejl',
    viewReqResp: 'vis',
    totalTime: 'Tid'
  },
  date: { date: 'Dato', today: 'I dag', tomorrow: 'I morgen', yesterday: 'I går', dateAndTime: 'Dato og tid' },
  routeReceipt: {
    printRouteReceiptList: 'Udskriv kvitteringsliste',
    noRoutesInfoAvailable: 'Ingen ruteinformation tilgængelig endnu'
  },
  homeDeliveryRouteList: {
    receiptList: 'Kvitteringsliste',
    shipments: 'Forsendelser',
    weight: 'Vægt',
    loadingMeters: 'Lastmeter',
    palletFootPrints: 'Pallpladser',
    sender: 'Afsender',
    package: 'Kolli',
    shipmentNo: 'Forsendelsesnummer',
    recipient: 'Modtager',
    time: 'Tid',
    mobile: 'Mobil',
    other: 'Øvrigt',
    attemptedDelivery: 'Forgæves kørsel',
    receipt: 'Kvittering',
    dateOfBirth: 'Fødselsdato',
    signature: 'Underskrift',
    nameClarification: 'Navn',
    goodsLine1: 'Husk at kontrollere varerne for synlige skader inden du underskriver.',
    goodsLine2: 'Hvis der er synlige skader, skal chaufførerne notere dem inden du som modtager underskriver.',
    damage: 'Skade',
    missing: 'Mangler',
    packageNumber: 'Kollinummer',
    comments: 'Kommentar'
  },
  shipment: {
    columnCategories: {
      aboutTheShipment: 'Om forsendelsen',
      goods: 'Varer',
      timeAndDate: 'Tid og dato',
      planning: 'Planlægning',
      pickupInformation: 'Afhentningsinformation',
      customer: 'Kunde',
      deliveryInformation: 'Leveringsinformation',
      originalSender: 'Oprindelig afsender',
      originalDelivery: 'Oprindelig levering',
      service: 'Service',
      preAdvice: 'Forhåndsråd',
      invoicingInformation: 'Faktureringsoplysninger'
    },
    shipment: 'Forsendelse',
    customer: 'Kunde',
    customerContact: 'Kontaktperson hos kunden',
    customerReference: 'Kundereference',
    customerNumber: 'Kundenummer',
    route: 'Rute',
    additionalServices: 'Ekstra services',
    quantity: 'Mængde',
    totalWeight: 'Total vægt',
    totalVolume: 'Total volumen',
    totalLoadingMeters: 'Lastmeter totalt',
    totalPalletFootPrints: 'Pallpladser totalt',
    goodsInfo: 'Vareoplysninger',
    packages: 'Pakker',
    pickupInfo: 'Afhentningsinformation',
    pickupDate: 'Afhentningsdato',
    pickupTime: 'Afhentningstid',
    pickupInstructions: 'Opsamlingsinstruktioner',
    deliveryInfo: 'Leveringsinformation',
    deliveryDate: 'Leveringsdato',
    deliveryTime: 'Leveringstid',
    receiverReference: 'Modtagers reference',
    deliveryInstructions: 'Leveringsinstruktioner',
    trackingPage: 'Modtagers sporingsside',
    internalNote: 'Notat',
    closeWhileEditing: 'Er du sikker på du vil lukke uden at gemme?\nAlle ændringer vil gå tabt.',
    name: 'Navn',
    address: 'Adresse',
    zipCode: 'Postnummer',
    zipArea: 'By',
    phone: 'Telefon',
    secondPhone: 'Telefon 2'
  },
  bookingTimeWindow: {
    week: 'Uge',
    bookButton: 'Bestil',
    customButton: ' Tilpasset tidsvindue',
    removeBookingButton: 'Fjern bestilling',
    bookedInfo: 'Bestilt {{date}} mellem {{timeWindow}}',
    notBookedInfo: 'Ikke bestilt',
    tableHeaderDate: 'Dato',
    tableHeaderSlots: 'Tilgængelige tidsvinduer',
    serverError: 'Der opstod en fejl på serveren. Prøv igen senere.',
    previousButton: 'Forrige',
    nextButton: 'Næste',
    noAvailableDeliveryTimes: 'Ingen tilgængelige tidsvinduer denne måned',
    customTimeTitle: 'Angiv tilpasset tidsvindue for forsendelse',
    customDateLabel: 'Dato',
    customTimeWindowLabel: 'Leveringstidsvindue',
    backFromCustomTimeButton: 'Tilbage til tilgængelige tidsvinduer',
    errorEarliestNotBeforeLatest: 'Tidligste leveringstid må være før seneste',
    arrivedAtTerminal: 'Ankommet på terminal',
    bookCustomTimeSlot: 'Book tidspunkt',
    shipmentBooked: 'Forsendelse booket',
    shipmentBookedWithCustomTimeWindow: 'Forsendelse booket med tilpasset tidsvindue',
    updateBooking: 'Opdater booking',
    removeTimeSlot: 'Fjern tidsvindue',
    bookingChanged: 'Booking er ændret med succes',
    bookingSuccessful: 'Booking blev udført med succes',
    customTimeWindowAdded: 'Tilpasset tidsvindue for forsendelse blev tilføjet med succes',
    timeFrom: 'Tid fra',
    timeTo: 'Tid til',
    timeFieldError: 'Det tidligste leveringstidspunkt skal være før det seneste',
    availableTimeSlot: 'Tilgængelige tidsvinduer',
    updateButtonTooltip: 'For at opdatere bookingen skal du først vælge et nyt tidsvindue'
  },
  manualOverride: {
    scannedFormDescription: 'Typen af ​​ankomstscanning udløses af, hvilken afdeling du vælger.',
    eventType: {
      delivered: 'Leveret',
      collected: 'Opsamlet',
      returned: 'Returneret',
      deviated: 'Afveget',
      scanned: 'Scannet',
      preadvised: 'Preadvice forsøg',
      'not-arrived-at-distributing-terminal': 'Ikke ankommet'
    },
    selectEventType: 'Vælg en type hændelse',
    noEventSelected: 'Ingen hændelse valgt',
    selectManifest: 'Vælg flight',
    noManifestSelected: 'Ingen flight valgt',
    disabledEventReason: {
      returned: ' - Afvigelse påkrævet',
      collected: ' - Disponering påkrævet',
      default: ' - Ikke tilladt'
    },
    fillDeliverOrReturnEventInfo: 'Udfyld navn, dato og tidspunkt',
    fillDeviationEventInfo: 'Vælg afvigelse og udfyld navn, dato og tidspunkt',
    fillGeneralEventInfo: 'Udfyld dato og tidspunkt',
    eventMessage: 'Hændelse skabt',
    allPackagesSelected: 'Alle kolli valgt',
    noActiveDriverError: 'Ingen aktive chauffører på enheden',
    selectPackages: 'Vælg kolli',
    selectDriver: 'Vælg bud',
    selectUnit: 'Vælg enhed',
    selectDepartment: 'Overstyr afdeling'
  },
  zendesk: {
    email: 'Din email:',
    confirmEmail: 'Gentag email:',
    send: 'Gem',
    missingEmail:
      'For at bruge den nye support portal, skal du registrere din Posten eller Bring email. Det skal kun gøres en gang.'
  },
  instantPlannerUnification: {
    message:
      'Punkterne Ad Hoc og Planlægning er nu lagt sammen. Gå til Planlægning for at finde tidligere Ad Hoc funktioner.',
    linkTitle: 'Læs mere her.'
  },
  shipmentFormPage: {
    findShipment: 'Find forsendelse',
    searchPhraseError: 'Du skal angive mindst 3 tegn',
    selectStateLabel: 'Vælg tilstand fra rullemenuen',
    selectDateLabel: 'Vælg datointerval fra rullemenuen',
    submit: 'Vis resultater',
    inputPlaceholder: 'Søg efter forsendelses-ID, telefonnummer, adresse, område, postnummer ...',
    clearButtonLabel: 'Ryd søgeudtryk-knap',
    states: 'Tilstande',
    allStates: 'Alle tilstande',
    customDate: 'Tilpasset',
    noDate: 'Ingen datobegrænsning',
    selectFromDate: 'Vælg fra dato',
    selectToDate: 'Vælg til dato',
    include: 'Inkluder',
    exclude: 'Ekskluder',
    selectAdvancedOperator: 'Vælg avanceret operator fra rullemenuen',
    ofTheseRequirements: 'af disse krav',
    tableColumn: 'Tabelkolonne',
    condition: 'Betingelse',
    searchTerm: 'Søgeterm',
    all: 'ALLE',
    any: 'ENHVER',
    tableDropdown: 'Vælg tabel fra rullemenuen',
    conditionDropdown: 'Vælg betingelse fra rullemenuen',
    typeSearch: 'Indtast søgeterm',
    addNewRow: 'Tilføj ny række',
    deleteRow: 'Slet nuværende række',
    service: 'Service',
    goodsDescription: 'Varebeskrivelse',
    route: 'Rute',
    unit: 'Enhed',
    deliveryArea: 'Leveringsområde',
    deliveryZipcode: 'Leveringspostnummer',
    state: 'Tilstand',
    today: 'I dag',
    tomorrow: 'I morgen',
    yesterday: 'I går',
    last30Days: 'Seneste 30 dage',
    plusMinus15Days: '+/- 15 dage',
    plusMinus30Days: '+/- 30 dage',
    shipmentIds: 'Flere forsendelser'
  },
  shipmentResultPage: {
    noResultTitle: 'Ingen resultater fundet',
    batchLimitReached: 'Viser maksimalt 2000 forsendelser, overvej at indskrænke din søgning',
    noResultExplanation: 'Vi kunne ikke finde nogen resultater. Prøv at søge med et andet udtryk.',
    withoutDateLimitReached:
      'Når der søges uden dato, vises der højst {{limit}} forsendelser. Overvej at indsnævre din søgning.'
  },
  shipmentTable: {
    quantity: 'Antal',
    delEta: 'LEV ETA',
    delDuration: 'LEV varighed',
    delDurationTitle: 'Leveringens varighed',
    delDeliveryFrom: 'LEV fra',
    delDeliveryFromTitle: 'Tidspunkt for levering fra',
    delDeliveryTo: 'LEV til',
    delDeliveryToTitle: 'Tidspunkt for levering til',
    timeOfDelivery: 'Leveringstidspunkt',
    puDuration: 'Afhent. varighed',
    puDurationTitle: 'Afhentningsvarighed',
    puPickupFrom: 'Afhent fra',
    puPickupFromTitle: 'Tidspunkt for afhentning fra',
    puPickupTo: 'Afhent til',
    puPickupToTitle: 'Tidspunkt for afhentning til',
    timeOfPickup: 'Tidspunkt for afhentning',
    delEtaTitle: 'Estimeret leveringstidspunkt',
    recipientRef: 'Modtagers ref',
    loading: 'Indlæser...',
    pickupName: 'Afhentningsnavn',
    deliveryName: 'Leveringsnavn',
    selectAllOnPage: 'Vælg alle på siden ({{count}})',
    selectAll: 'Vælg alle ({{count}})',
    deselectAll: 'Fravælg alle',
    numSelectedShipments: '{{numSelected}} valgt',
    createLabels: 'Opret etiketter',
    editShipments: 'Rediger forsendelsesoplysninger',
    moveShipments: 'Flyt til en anden afdeling',
    yourSearchResult: 'Dit søgeresultat',
    numberOfShipmentsFound: '{{count}} forsendelser fundet'
  },
  shipmentResultActions: {
    confirmDeleteTitle: 'Slette forsendelse?',
    confirmDuplicateTitle: 'Duplikere forsendelse?',
    confirmDuplicateButton: 'Duplikere',
    deleteSuccess: 'Forsendelsen blev slettet med succes',
    deleteFailure: 'Kunne ikke slette forsendelsen'
  },
  tablePagination: {
    goToPage: 'Gå til side',
    page: 'Side',
    of: 'af'
  },
  moveDepartmentModal: {
    errorMessage: 'Ordre(r): {{orderIds}}, fejl: {{error}}',
    errorMessage_plural: 'Ordre(r): {{orderIds}}, fejl: {{error}}',
    ordersMovedText: 'Ordre(r) {{orderIds}} er blevet flyttet til afdeling {{department}}',
    ordersMovedText_plural: 'Ordre(r) {{orderIds}} er blevet flyttet til afdelingen {{department}}',
    partialSuccessMessage: '{{count}} af {{fullCount}} forsendelse blev flyttet',
    title: 'Flyt valgte forsendelse til en anden afdeling'
  },
  sendManualDelaySmsModal: {
    errorMessage: '{{error}} {{shipmentIds}}',
    partialSuccessMessage: '{{count}} af {{fullCount}} SMS blev sendt',
    successMessage: 'Manuel forsinkelses-SMS blev sendt med succes'
  },
  multistopModal: {
    title: 'Gruppeinformation',
    description: 'Gruppevisning for ordrer, der betales sammen.',
    debitLines: 'Debetlinjer',
    notFound: 'Ingen gruppeinformation fundet',
    shipmentId: 'Forsendelsenummer',
    deliveryDate: 'Leveringsdato',
    stopAddress: 'Stopadresse',
    postalCode: 'Postnummer',
    noPrice: 'Ingen prisoplysninger tilgængelige for gruppe',
    priceUpdated: 'Gruppens priser er blevet opdateret'
  },
  shipmentDetails: {
    eventWasAdded: 'Begivenhed blev tilføjet',
    issueSaving: 'Der opstod et problem med at gemme',
    noSelectedPackages: 'Ingen pakker blev valgt',
    pickupAndDelivery: 'Afhentning og levering',
    whereIsTheOrder: 'Hvor er ordren',
    communicationToRecipient: 'Kommunikation til modtager',
    yesPremium: 'Ja, premium',
    alerts: 'Advarsler',
    pickupDeliveryInfo: 'Afhentnings- og leveringsoplysninger',
    quickEdit: 'Hurtig redigering',
    quickEditTitle: 'Rediger afhentnings- og leveringsoplysninger',
    aboutShipment: 'Om forsendelsen',
    mapLinkText: 'Vis kortkoordinater',
    copyShipment: 'Kopiér forsendelse',
    editShipment: 'Rediger forsendelsesoplysninger',
    location: 'Placering',
    time: 'Tid',
    selectDate: 'Vælg dato',
    manualChangeEvent: 'Manuelt ændre begivenhed',
    disabledEventReason: {
      returned: 'Afvigelse kræves',
      collected: 'Skal tildeles rute',
      default: 'Ikke tilladt'
    },
    selectDeviation: 'Vælg afvigelse',
    required: 'Påkrævet',
    allPackages: 'Alle pakker',
    packages: 'Pakker',
    package: 'Pakke',
    changeDepartment: 'Skift afdeling',
    moveDepartment: 'Flyt afdeling',
    shipmentMoved: 'Forsendelse flyttet',
    shipmentMovedText: 'Forsendelse {{shipmentId}} er blevet flyttet til afdeling {{department}}',
    shipmentMovedGoBack: 'Gå tilbage til søgesiden',
    moveShipmentDescription:
      'Hvis du ikke har adgang til afdelingen, vil du ikke være i stand til at finde forsendelsen efter flytning.',
    moveShipmentConfirmText: 'Flyt forsendelse til en anden afdeling?',
    addShipmentToSlot: 'Tilføj forsendelse til {{slot}}?',
    changeSlotConfirm: 'Skift slot for forsendelse til {{slot}}?',
    changeSlot: 'Skift slot',
    selectSlot: 'Vælg slot',
    AddToSlot: 'Tilføj til slot',
    optimisationInProgress: 'Optimering i gang. Du kan ikke ændre slot, mens ruten optimeres.',
    removeFromSlot: 'Fjern fra slot',
    removeShipmentFromSlot: 'Fjern forsendelse fra slot',
    removeShipmentFromSlotHeading: 'Fjern forsendelsen fra dette slot?',
    successRemoveFromSlot: 'Forsendelsen blev fjernet fra slot',
    successChangeSlot: 'Forsendelsen skiftede slot',
    successAddToSlot: 'Forsendelsen blev tilføjet til slot',
    changeSlotDescription: 'Skift af slot udføres uden ruteoptimering.',
    addToSlotDescription:
      'Det valgte slot vil blive optimeret med de eksisterende forsendelser, der forbliver i samme rækkefølge',
    pickupOrDeliveryChange: 'Afhentning eller levering er blevet ændret',
    pickupOrDeliveryChangeDescription:
      'Afhentnings- eller leveringsadressen er blevet ændret. Hvis du vil ændre adressen tilbage, skal du trykke på en af knapperne nedenfor.',
    addressWasUnresolved: 'Adressen var uløst'
  },
  location: 'Placering',
  scannedAtDelegatedDepartment: 'Scannet fra den delegerede afdeling.',
  pickupOrders: {
    acceptOrders: 'Accepter ordrer',
    selected: 'valgt',
    handleOrders: 'Håndter afhentningsordrer',
    found: 'fundet',
    accept: 'Accepter',
    reject: 'Afvis',
    noResultText: 'Vi kunne ikke finde nogen resultater. Prøv at søge med en anden dato.',
    last7d: 'I dag + de sidste 7 dage',
    rejectOptions: {
      notFound: 'Afsender ikke fundet på afhentningsadressen. Ingen flere forsøg. Afhentning lukket.',
      terminatesPickupOrder: 'Afsender afslutter afhentningsordre. Ingen flere forsøg. Afhentning lukkes.',
      handedOver: 'Afsender har overdraget afhentning til en anden transportør. Ingen flere forsøg. Afhentning lukkes.',
      noMoreAttempts: 'Afsender er flyttet. Ingen flere forsøg. Afhentning lukkes.',
      pickupCorrected: 'Afsender er flyttet. Afhentning rettet og sendt tilbage til underleverandør.',
      goodsNotReady: 'Varer ikke klar til afhentning. Ingen flere forsøg. Afhentning lukkes.',
      incorrectBooking: 'Forkert booking. Afhentning afsluttet.',
      senderRefuses: 'Afsender nægter at overdrage varer. Ingen flere forsøg. Afhentning lukkes.',
      addressCorrected: 'Forkert afhentningsadresse. Adresse rettet.',
      incorrectAddressNoMoreAttempts: 'Forkert afhentningsadresse. Ingen flere forsøg. Afhentning lukkes.',
      senderNotified: 'Forkert afhentningsadresse. Ingen flere forsøg. Afhentning lukkes.',
      forbiddenGoods: 'Forbudte varer. Ingen flere forsøg. Afhentning lukkes.',
      timeFrame:
        'Afhentning kan ikke udføres inden for tidsrammen. Afhentningsordre sendt tilbage til reservationskontoret.'
    },
    mustSelectReason: 'Du skal vælge en grund til afvisning',
    selectRejectReason: 'Vælg årsag til afvisning*',
    selectReason: 'Vælg årsag',
    commentPlaceholder: 'Valgfrit - angiv ekstra information til afvisning',
    acceptedSuccessToast: 'blev accepteret med succes',
    rejectedSuccessToast: 'blev afvist med succes',
    errorToast: 'Noget gik galt',
    rejectOrders: 'Afvis ordrer',
    shipment: '{{count}} forsendelse',
    shipment_plural: '{{count}} forsendelser'
  },
  editShipments: {
    pickupShouldBeBeforeDelivery: 'Afhentningstidsvindue skal være før leveringstidsvinduet',
    pickupEarliestShouldBeBeforeLatest: 'Den tidligste afhentningstid skal være før den seneste',
    deliveryEarliestShouldBeBeforeLatest: 'Den tidligste leveringstid skal være før den seneste',
    success: 'Forsendelsesoplysninger blev opdateret med succes',
    pickupDateTime: 'Opsamling dato og tid',
    deliveryDateTime: 'Levering dato og tid',
    earliestPickupTime: 'Tidligste opsamlingstidspunk',
    latestPickupTime: 'Seneste opsamlingsstidspunkt',
    earliestDeliveryTime: 'Tidligste leveringstid',
    latestDeliveryTime: 'Seneste leveringstid',
    pickupName: 'Opsamlingsnavn',
    pickupPhoneNumber: 'opsamlingstelefonnummer',
    deliveryName: 'Leveringsnavn',
    deliveryPhoneNumber: 'Leveringstelefonnummer',
    pickupInstructions: 'Opsamlingsinstruktioner',
    deliveryInstructions: 'Leveringsinstruktioner',
    mixedShipmentsSelected:
      'Du har valgt forsendelser med og uden en dato. Afhentnings- og leveringsoplysninger kræves.',
    fieldNotEditable: 'Disse oplysninger kan ikke redigeres for ordrer i tilstand Tildelt til spor eller Indsamlet',
    bulkActionDisabledReason: 'Ordrer i tilstand Afviger, Leveret, Afvist eller Returneret kan ikke redigeres',
    withoutDateDisabledReason:
      'Kombinationerne af ordrer i tilstand Tildelt til spor eller Indsamlet og uden dato kan ikke redigeres'
  },
  shipmentSelectColumn: {
    title: 'Tilpas tabeloverskrifter',
    addColumnHeader: 'Tilføj kolonneoverskrifter',
    addColumnBody:
      'Vælg de overskrifter, du vil have vist i datatabellen. Vores tip er at bruge 10-15 forskellige overskrifter for at undgå, at informationen bliver klippet af. Det nøjagtige antal afhænger af størrelsen på enheden, du arbejder på.',
    orderOfTable: 'Rækkefølge af tabeloverskrifter',
    orderOfTableDescription: 'Vælg den foretrukne rækkefølge for tabeloverskrifterne ved at trække og slippe.'
  },
  bulkActions: {
    routeReceiptList: {
      disabledTooltip: 'Ordrer kan ikke være i tilstand Resolved eller Unresolved'
    },
    unassignShipmentFromSlot: 'Fjern tildeling af forsendelser fra spor',
    unassignShipmentFromSlotDisabledReason: 'Ordrer skal være i tilstand Tildelt til spor eller Tilbudt',
    forceUnassignShipmentFromSlot: 'Tving fjernelse af tildeling af forsendelser fra spor',
    disabledMoveDepartmentTooltip:
      'Ordrer skal være i tilstand Oprettet, Løst, Uløst eller Afvist for at blive flyttet',
    deleteDisableTooltip:
      'Forsendelsen kan ikke slettes, hvis den er i tilstand Oprettet, Uløst, Løst, Tilbudt eller Tildelt',
    duplicateDisableTooltip:
      'Forsendelser skal være i tilstand Afviget eller Returneret, og afdelingstypen skal være Kurer',
    creditDisableTooltip: 'Forsendelserne skal have Invoicing Status af "Invoiced OK"',
    viewGroupDisabledTooltip: 'Ordren skal tildeles, før du kan se og redigere dens gruppe'
  },
  vehicle: { weightCapacity: 'Vægtkapacitet', volumeCapacity: 'Volumenkapacitet', maxRangeKm: 'Rækkevidde' },
  invoicingStatus: {
    INVOICING_FAILED: 'Fakturering mislykkedes',
    CORRECTION_PENDING: 'Rettelse afventer',
    INVOICING_PAUSED: 'Fakturering sat på pause',
    EXCLUDED_FROM_INVOICING: 'Udelukket',
    NOT_READY_FOR_INVOICING: 'Ikke klar',
    INVOICED_OK: 'Faktureret OK',
    ZERO_PRICE: 'Ufuldstændig pris'
  },
  invoice: {
    articleCode: 'Artikel',
    allOrdersInvoice: 'Alle ordrer faktureres',
    allOrdersInvoiceDescription: 'Vi kunne ikke finde nogen ufakturerede ordrer.',
    handledBy: 'Håndteres af',
    invoicing: 'Fakturering',
    credit: 'Kredit',
    invoice: 'Faktura',
    create: 'Opret',
    invoiceOrderAction: 'Faktura ordre',
    customerNumber: 'Kundenummer',
    carrierNumber: 'Enhedsnummer',
    details: 'Detaljer',
    information: 'Fakturaoplysninger',
    reference: 'Faktura reference',
    items: 'Artiklar',
    quantity: 'Antal',
    description: 'Beskrivelse',
    customerAmount: 'Kundebeløb',
    carrierAmount: 'Enhedsbeløb',
    totalCustomerAmount: 'Samlet kundebeløb',
    totalCarrierAmount: 'Samlet enhedsbeløb',
    invoiceDepartment: 'Faktura afdeling',
    invoiceDepartment_plural: 'Faktura afdelinger',
    invoiceDepartments: 'Fakturaafdeling(er)',
    dashboard: 'Dashboard',
    invoicingJobsSummary: 'Fakturering joboversigt',
    invoicingStatus: 'Faktureringsstatus',
    invoicingStatusDescription: 'Viser ordrer fra de sidste 3 måneder.',
    notInvoicedOrders: 'Ikke fakturerede ordrer',
    ordersOnPage: 'Viser {{count}} ordrer på siden',
    ordersFoundCount: '{{count}} ordre fundet',
    ordersFoundCount_plural: '{{count}} ordrer fundet',
    toBeInvoiced: 'Skal faktureres',
    ordersNotInvoiced: 'Ordrer, der ikke er faktureret endnu',
    invoicingFailed: 'Fakturering mislykkedes',
    invoicingFailedDescription: 'Ordrer kunne ikke behandles',
    text: 'Fakturatekst',
    correctionPending: 'Med ordrerettelse',
    correctionPendingDescription: 'Ordrer med afventende rettelser',
    invoicingPaused: 'Fakturering sat på pause',
    ordersPausedFromInvoiced: 'Ordrer, der er sat på pause fra at blive faktureret',
    invoicingZeroPrice: 'Ufuldstændig pris',
    invoicingZeroPriceDescription: 'Ordrer med ikke-godkendte nul-linjer',
    sendInvoices: 'Send fakturaer',
    sendInvoicesDescription:
      'Du er ved at fakturere {{afdelinger}}. Vælg venligst dato eller datointerval for faktureringsperioden.',
    sendInvoiceDateValidationError: 'Fra dato kan ikke være senere end Til dato',
    sendInvoiceDateValidationErrorOneMonth: 'Maksimal datospænding er 1 måned',
    invoicingInProgress: 'Fakturering for {{departmentName}} er i gang, {{count}} faktureringsjob er tilbage',
    invoicingInFinished: 'Fakturering for {{departmentName}} er afsluttet',
    orderSuccessInvoice: 'Ordren blev faktureret',
    orderSuccessInvoice_plural: 'Ordrer blev faktureret',
    orderSuccessNotInvoiced: 'Ordren blev ændret til ikke faktureret',
    orderSuccessNotInvoiced_plural: 'Ordrer blev ændret til ikke faktureret',
    orderFailedInvoice: 'Fakturering for valgt ordre mislykkedes',
    howManyInvoices: 'Hvor mange fakturaer vil blive sendt',
    fetchAmount: 'Hent beløb',
    wantToResend: 'Denne ordre er allerede blevet faktureret. Er du sikker på, at du vil sende den igen',
    invoiceSingleOrder: 'Du er ved at fakturere denne ordre. Vil du fortsætte',
    pauseOrders: 'Sæt ordrer på pause',
    datePeriod: 'Dato periode',
    ordersWereSuccessfullyPaused: '{{count}} ordre blev sat på pause fra fakturering',
    ordersWereSuccessfullyPaused_plural: '{{count}} ordrer blev sat på pause fra fakturering',
    releaseOrders: 'Frigiv ordrer',
    ordersWereSuccessfullyReleased: '{{count}} ordre blev frigivet til fakturering',
    ordersWereSuccessfullyReleased_plural: '{{count}} ordrer blev frigivet til fakturering',
    ordersWereSuccessfullyAcceptedWithIncompletePrice: '{{count}} ordre blev accepteret med ufuldstændig pris',
    ordersWereSuccessfullyAcceptedWithIncompletePrice_plural: '{{count}} ordrer blev accepteret med ufuldstændig pris',
    addUnit: 'Tilføj enhed',
    addUnitDescription: 'Tilføj en enhed til dine valgte ordrer. Denne enhed er den, der vil blive selvfaktureret.',
    addUnitToOrders: 'Tilføj enhed til ordrer',
    addUnitToOrder: '{{unit}} blev tilføjet til {{count}} ordre',
    addUnitToOrder_plural: '{{unit}} blev tilføjet til {{count}} ordrer',
    addUnitBulkDisabled: 'Enhed kan kun tilføjes til ordrer uden enhed',
    approveIncompletePrices: 'Godkjend med mangelful pris',
    manualOverrideTitle: 'Manuel tilsidesættelse af faktureringsstatus',
    manualOverrideModalQuestion:
      'Du er ved at tilsidesætte status for {{count}} ordrer. De valgte ordrer får status Faktura OK. Er du sikker på, at du vil fortsætte?',
    unitId: 'Enheds-id',
    bannerOldInvoicePage:
      'Den 26. august vil <0>den nye faktureringsside</0> åbne som standard. Vi opfordrer dig til at begynde at bruge den nye side. Mere info om den nye side <1>her</1>.',
    acceptIncompletePricesTitle: 'Godkend ufuldstændige priser',
    acceptIncompletePricesModalQuestion:
      'Du er ved at godkende {{count}} ordre med ufuldstændige priser. Er du sikker på, at du vil fortsætte?',
    acceptIncompletePricesModalQuestion_plural:
      'Du er ved at godkende {{count}} ordrer med ufuldstændige priser. Er du sikker på, at du vil fortsætte?',
    confirmCancelDialog: {
      title: 'Du har ikke-gemte ændringer',
      question: 'Du har ikke-gemte ændringer. Ønsker du at fortsætte?',
      abort: 'Bliv på siden',
      confirm: 'Fortsæt uden at gemme'
    }
  },
  live: {
    onlyDeviated: 'Vis kun afvigede ruter',
    bannerOldLivePage:
      '<0>Prøv</0> den nye Live-side med forbedrede funktionaliteter og nyt design. Vi opfordrer dig til at bruge den nye live-side, så du kan vænne dig til den, før vi fjerner siden fuldstændigt.',
    shipment: 'Forsendelse',
    shipmentFrom: 'Fra',
    shipmentTo: 'Til',
    confirm: 'Bekræfte',
    unconfirm: 'Fortryd',
    confirmedBy: 'Bekræftet af {{name}} kl. {{time}}',
    theCourier: 'Budet',
    activeRoutes: 'Aktive ruter',
    routeDeviation: 'Ruteafvigelse',
    routeDeviations: 'Ruteafvigelser',
    multipleDeviations: 'Flere afvigelser',
    recentDeviations: 'Seneste afvigelser',
    noRoutesFound: 'Ingen ruter fundet',
    noDeviationsFound: 'Ingen afvigelser fundet',
    consignmentDamage: 'Forsendelsesskader',
    shipmentDeviation: 'Forsendelsesafvigelse',
    outsideServiceTime: 'Udenfor servicetid',
    outsideEstimatedTime: 'Udenfor estimeret tid',
    deliveryLocationDeviation: 'Uventet placering',
    eventTextDeviationOne: '{{name}} har en forsendelsesafvigelse.',
    eventTextDeviationMany: '{{name}} har {{count}} forsendelsesafvigelser.',
    eventTextDamageOne: '{{name}} har skader på {{count}} forsendelser.',
    eventTextDamageMany: '{{name}} har skader på en forsendelse.',
    eventTextServiceTimeWindow: '{{name}} leveret uden for servicetidsvinduet.',
    eventTextEstimatedTimeWindow: '{{name}} leveret uden for det estimerede tidsvindue.',
    eventTextDeviated: '{{name}} har afveget fra deres rute.',
    eventHelpDeviation: '{{name}} har en afvigelse på forsendelsesnummer {{shipmentId}}.',
    eventHelpDamage: '{{name}} har en skade på forsendelsesnummeret {{shipmentId}}.',
    eventHelpDriverDeviated: '{{name}} har leveret forsendelsesnummer {{shipmentId}} i den forkerte rækkefølge.',
    filterOngoing: 'Igangværende',
    filterNotStarted: 'Ikke startet',
    filterFinished: 'Færdig',
    filterOnlyDeviated: 'Viser kun ruter med afvig',
    filterShipmentDeviations: 'Forsendelsesafvigelser',
    filterShipmentDamage: 'Forsendelsesskade',
    filterEstimatedTime: 'Estimeret tid',
    filterServiceTime: 'Servicetid',
    filterRouteDeviations: 'Ruteafvigelser',
    filterDeliveryLocationDeviation: 'Uventet placering',
    filterConfirmed: 'Bekræftet',
    routeProgress: 'Fuldført {{complete}} af {{total}}',
    routeDuration: 'Fra {{from}} til {{to}}',
    routeDelayed: 'Forsinket',
    routeAhead: 'Foran',
    routeOnTime: 'En gang',
    routeUnknown: 'Ukendt',
    routeDeviationsNone: 'Ingen afvigelser',
    routeDeviationCount: '{{unhandledDeviations}} af {{totalDeviations}}',
    routeHasHandleDeviations: 'Rute har {{count}} håndteret afvigelse',
    routeHasHandleDeviations_plural: 'Rute har {{count}} håndteret afvigelser',
    openRoute: 'Se rute',
    openShipment: 'Se forsendelse',
    viewRoute: 'Se rute',
    deviated: 'Afviget',
    offRoute: 'Følger ikke ruten',
    onRoute: 'På rute',
    allRoutesStates: 'Alle rutestatusser',
    selectedRouteStates: '{{count}} rutestatus',
    selectedRouteStates_plural: '{{count}} rutestatusser',
    noSelectedStates: 'Ingen status valgt',
    allDeviationTypes: 'Alle afvigelsestyper',
    selectedDeviationTypes: '{{count}} afvigelsestype',
    selectedDeviationTypes_plural: '{{count}} afvigelsestyper',
    showHandledDeviations: 'Vis håndterede afvigelser ({{count}})',
    noSelectedTypes: 'Ingen valgte typer',
    viewShipment: 'Se forsendelse',
    selectAllRouteStates: 'Vælg alle rutetilstande',
    noRoutesWithDeviations: 'Ingen tilgængelige ruter',
    selectAllDeviationTypes: 'Vælg alle afvigelsestyper',
    noDeviationOnRoute: 'Ingen afvigelser på rute',
    noDeviationAvailable: 'Ingen tilgængelige afvigelser',
    noDeviationsOnFilter: 'Der er ingen tilgængelige afvigelser for det valgte filter. Prøv et andet filter',
    noRoutesOnFilter: 'Der er ingen tilgængelige ruter for det valgte filter. Prøv et andet filter',
    eventHelpServiceTimeWindow:
      '{{name}} har leveret forsendelsesnummer {{shipmentId}} på {{actual}}, hvilket er uden for servicetidsvinduet {{earliest}}-{{seneste}}.',
    eventHelpDeliveryLocationDeviation: {
      withShipmentId:
        '{{name}} har leveret eller afveget forsendelsesnummer {{shipmentId}} <4>her</4>, {{distance}} meter væk fra den <9>forventede placering</9>.',
      withoutShipmentId:
        '{{name}} har leveret eller afveget <4>her</4>, {{distance}} meter væk fra den <9>forventede placering</9>.'
    },
    eventHelpDriverDeviatedBefore:
      '{{name}} har leveret forsendelsesnummer {{shipmentId}} i den forkerte rækkefølge ved at levere #{{actual}} før levering #{{expected}}.',
    eventHelpDriverDeviatedAfter:
      '{{name}} har leveret forsendelsesnummer {{shipmentId}} i den forkerte rækkefølge ved at levere #{{actual}} efter levering #{{expected}}.',
    search: 'Søg efter rute, enhed eller bude',
    unhandled: 'Ubehandlede',
    deviations: 'afvigelser',
    mostDeviations: 'Flest afvigelser',
    latestInTime: 'Senest i tiden',
    earliestInTime: 'Tidligst i tiden',
    sortOn: 'Sorter efter'
  },
  userRolesAdmin: {
    userRoles: 'Brugerroller',
    successCreateMessage: '{{name}} blev oprettet',
    successUpdateMessage: '{{name}} blev opdateret',
    saveRole: 'Gem rolle',
    editRole: 'Rediger {{name}}',
    createNewRole: 'Opret ny rolle',
    nameOfRole: 'Rollenavn',
    nameRequired: 'Navn er påkrævet',
    descriptionRequired: 'Beskrivelse er påkrævet',
    descriptionLabel: 'Beskrivelse (max 240 tegn)',
    iconLabel: 'Vælg hvilket ikon du ønsker for brugerrollen',
    selectRoleTitle: 'Vælg adgangsret for rolle',
    emptyState: 'Ingen roller fundet for søgefrasen',
    deleteConfirm: 'Er du sikker på at du vil slette {{name}}?',
    deleteSuccess: 'Rolle {{name}} blev slettet',
    settings: 'Indstillinger',
    accessRights: 'Adgangsrettigheder',
    settingsTitle: 'Indstillinger for rolle',
    settingsDescription: 'Engelsk er påkrævet. Hvis intet andet sprog vælges, vil engelsk være fallback.',
    additionalLanguagesTitle: 'Tilføj yderligere sprog',
    customerPortalAccess: 'Kundeportal adgang',
    defaultForUserRole: 'Brug som standardrolle (valgfrit)'
  },
  validationError: {
    provideEmail: 'Du skal angive en e-mail',
    provideValidEmail: 'Du skal angive en gyldig e-mail',
    youMustSelectCustomer: 'Du skal vælge en kunde'
  },
  apolloAdmin: {
    title: 'Integrationer',
    emptyTitle: 'Ingen requests',
    emptyDescription: 'Vælg den dato du vil se og tryk på søg.',
    reqType: 'Request-type',
    routeId: 'Rute-ID',
    routeName: 'Rutenavn'
  }
}
export default dk
